<template>
  <div id="content">
    <div class="header">
      <div class="logo">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <div class="nav-item">
        <div
          v-for="(item, i) in navList"
          :key="i"
          @click="clicknavList(i)"
          class="navitems"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="center">
      <div v-if="active == '1'" class="home">
        <div></div>
      </div>
      <div v-else-if="active == '2'" class="company">
        <img src="../assets/img/gsjj.jpg" alt="" />
        <div class="introduce">
          <p>
            四川易链数博科技文化有限公司成立于2017年2月20日，是四川易利数字城市科技有限公司的全资子公司，公司主要从事软件开发、信息技术咨询服务、互联网信息服务、文物保护工程设计施工等业务，公司还拥有拍卖资质。
          </p>
          <p>
            公司专注于做智慧文旅运营及项目，坚持以科技赋能文化旅游产业转型发展，聚焦文旅互联网运营、智慧文旅信息化集成与建设、文创智库三大领域，着力推动文旅产业数字化融合、共享和协同；已经建设完成景区实景孪生时空态势感知平台、创客IP原创保护平台文旅行业服务中心、文化云公共服务平台、AI游记等一系列文旅服务平台。
          </p>
        </div>
      </div>
      <div v-else-if="active == '3'" class="news">
        <img src="../assets/img/新闻中心.jpg" alt="" />
        <div class="contentbox">
          <div class="newscontent">
            <img src="../assets/img/图片1.png" alt="" />
            <div @click="clicknavList('7')">
              <h4>易链数博亮相第六届数字中国建设成果展览</h4>
              <p>2023-04-27 19:24</p>
            </div>
          </div>
          <div class="newscontent">
            <img src="../assets/img/图片2.png" alt="" />
            <div @click="clicknavList('8')">
              <h4>5·10中国品牌日，川渝数藏发布会圆满举行！</h4>
              <p>2023-05-16 11:53</p>
            </div>
          </div>
          <div class="newscontent">
            <img src="../assets/img/图片19.png" alt="" />
            <div @click="clicknavList('9')">
              <h4>
                5月15日“川渝数藏”正式发布，川渝两省市领导出席启动上线仪式！
              </h4>
              <p>2023-05-16 15:48</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="active == '4'" class="product">
        <img src="../assets/img/产品介绍.jpg" alt="" />
        <div class="center-content">
          <div
            class="title-box"
            v-for="(item, i) in productList"
            :key="i"
            @click="clickDetails(item)"
          >
            <img :src="item.url" />
            <!-- <img src="../assets/img/神鸟.gif" /> -->
            <div class="title">
              <h6>{{ item.title }}</h6>
              <div class="price">
                <p>￥{{ item.price }}</p>
                <span>库存：{{ item.bank }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="active == '6'" class="briefings">
        <div>
          <h1>易链数博亮相第六届数字中国建设成果展览</h1>
          <p>
            4月26日，第六届数字中国建设峰会在福州开幕，四川易链数博科技文化有限公司受邀参展。由旅投信产公司副总经理马健勋带队，易链数博携文旅数字化创新实践案例亮相数字中国建设成果展览，在展会现场获得广泛关注。
          </p>
          <img
            style="width: 300px; height: 500px"
            src="../assets/img/图片1.png"
            alt=""
          />
          <p>
            数字中国建设峰会是经党中央批准，为全面贯彻落实习近平总书记关于网络强国的重要思想，广泛凝聚起社会各界关于加快建设网络强国、数字中国、数字社会的共识而举办的盛会，习近平总书记两度向数字峰会致贺信，为办好峰会、推进数字中国建设提供了根本遵循。
          </p>
          <p>
            本届峰会以“加快数字中国建设，推进中国式现代化”为主题，充分展示数字中国建设最新成果，全力打造一场开放共享、创新互动、安全高效的数字盛会。
          </p>
          <img src="../assets/img/图片7.png" alt="" />
          <p>
            自成立以来，易链数博坚持以科技赋能文化旅游产业转型发展，深度挖掘文化旅游资源价值点，建设文化旅游数字化产业。着力推动文旅产业数字化融合、共享和协同，构建数字化文旅融合发展新模式，助力四川文旅产业转型升级。目前，易链数博为众多文旅企事业单位提供了全方位全生命周期数字化服务，实现端到端、线上到线下、数字与实物全覆盖，大力推动了文化旅游数字化创新实践发展，全力建设文旅数字化新标杆。
          </p>
          <img
            style="margin-bottom: 10px"
            src="../assets/img/图片8.png"
            alt=""
          />
          <img
            style="margin-bottom: 10px"
            src="../assets/img/图片9.png"
            alt=""
          />
          <img src="../assets/img/图片10.png" alt="" />
          <p>
            展会期间，省委网信办副主任宁方伟、省国资委副主任薛东兵等领导先后到馆视察，详细了解易链数博文旅数字化创新实践的建设情况和运营思路。
          </p>
        </div>
      </div>
      <div v-else-if="active == '7'" class="briefings">
        <div>
          <h1>5·10中国品牌日，川渝数藏发布会圆满举行！</h1>
          <p>
            2023年5月10日，我们迎来了第七个“中国品牌日”，中国品牌博览会顺利在上海开幕！四川省旅投集团展馆“魅力天府”亮相现场，展现“川字号”品牌！
          </p>
          <p>
            为祝贺2023中国品牌日的成功开幕，“魅力天府”的惊艳亮相！5月10日-11日川渝数藏准备了两款重磅数字藏品，现身发布会现场。
          </p>
          <img src="../assets/img/图片3.png" alt="" />
          <p>
            5月10日下午两点半，发布会正式拉开序幕！秉持着“深度挖掘文化旅游资源价值点，为文旅爱好者带来丰富的数字臻品”的宗旨，川渝数藏发布会第一天，我们带来了第一款重磅数字藏品--《成都矛》！
          </p>
          <p>
            它是跨越了两千多年的成都制造，是成都城市的见证者！它的出现，把成都的城市得名时间推至战国，为历史学者的研究提供了高价值的信息！
          </p>
          <img
            style="margin-bottom: 10px"
            src="../assets/img/图片4.png"
            alt=""
          />
          <p>
            5月11日，在发布会的第二天我们带来了另一件值得珍藏的数字藏品--《康熙题字》！
          </p>
          <p>《康熙题字》是康熙皇帝亲笔御赐降龙院住持僧元亨的四言诗字幅。</p>
          <p>
            现场的朋友在看到藏品时，也不禁讨论起康熙帝的书法风格！康熙帝的书法造诣极深，汇集了诸家之象，具有清新洒脱的特点，极大地影响了清初文坛整体书风的形成与嬗变！
          </p>
          <img src="../assets/img/图片5.png" alt="" />
          <p>
            跨越百年千年的传奇文物，与现代科技的碰撞，我们带领着现场的朋友领略了文物数字藏品的魅力。持续两天，让大家真实的感受到文物“活起来”！
          </p>
          <p>
            5月10日-11日每天下午三点，《成都矛》《康熙题字》在现场也同步开启发售！现场观众纷纷拿起手机进入“川渝数藏APP”参与抢购，销售火热！
          </p>
          <p>
            在此，感谢现场以及线上各位朋友对数字藏品《成都矛》和《康熙题字》的喜爱，同时也非常感谢大家对川渝数藏的信任与支持！
          </p>
        </div>
      </div>
      <div v-else-if="active == '8'" class="briefings">
        <div>
          <h1>5月15日“川渝数藏”正式发布，川渝两省市领导出席启动上线仪式！</h1>
          <p>
            5月15日上午，“川渝数藏”文旅数字资产交易平台启动上线仪式在成都市金牛宾馆顺利举行。
          </p>
          <img src="../assets/img/图片11.png" alt="" />
          <p>
            四川省人民政府副省长胡云，重庆市人民政府副市长但彦铮，四川省人民政府副秘书长李君臣，四川省文化和旅游厅党组书记、厅长戴允康，重庆市文化和旅游发展委员会党委书记、主任冉华章，四川省旅投集团党委书记、董事长任丁，重庆旅投集团党委书记、董事长冯阳，重庆市文化和旅游协会会长王定国共同登台，正式启动“川渝数藏”上线仪式。
          </p>
          <img
            style="padding-bottom: 10px"
            src="../assets/img/图片12.png"
            alt=""
          />
          <img src="../assets/img/图片13.png" alt="" />
          <p>
            发布仪式前，信产公司董事、总经理孔维与重庆旅游云信息科技有限公司董事长罗锐、重庆市旅游营销中心有限公司董事长刘望共同上台签约。
          </p>
          <img
            style="padding-bottom: 10px"
            src="../assets/img/图片14.png"
            alt=""
          />
          <img src="../assets/img/图片15.png" alt="" />
          <p>
            “川渝数藏”是由四川省旅投集团旗下旅投信产公司打造的川渝文化旅游数字藏品交易平台。
          </p>
          <img src="../assets/img/图片16.png" alt="" />
          <p>
            自2023年2月5日试运营启动以来，共发布了六款数字藏品。分别是2月份发布的《纪念币“神鸟”》、3月份发布的《创世·虎纽印章》、4月份发布的《纪念币“芙蓉花”》《纪念币“锦江宾馆”》、5月份发布的《成都矛》《康熙题字》。
          </p>
          <p>
            川渝数藏每个月都在努力为大家带来了高质量的数字臻品。这六款藏品上线后也深受广大藏友的喜爱，特别是纪念币系列和创世印章，在上线发售数秒内售罄！
          </p>
          <p>
            近来，“川渝数藏”平台先后在中国品牌日、数字中国成果展等全国性展会上进行展览推广，市场反响良好，业界广泛认可。
          </p>
          <img
            style="padding-bottom: 10px"
            src="../assets/img/图片17.png"
            alt=""
          />
          <img src="../assets/img/图片18.png" alt="" />
          <p>
            非常感谢上线以来各位新老用户的信任与支持，你们对川渝数藏的喜爱，给予我们无限的动力！
          </p>
          <p>
            未来，川渝数藏定不负期望，持续挖掘文化旅游资源价值点，为各位带了更多高质量、高价值的数字臻品！
          </p>
        </div>
      </div>
      <div v-else-if="active == '5'" class="aboutbox">
        <img src="../assets/img/联系我们.jpg" alt="" />
        <div class="about">
          <div class="phone">联系我们：028-8417 9100</div>
          <div>
            公司地址：中国（四川）成都市金牛区天龙大道1333号6幢19层1-2号
          </div>
        </div>
      </div>
      <div v-else-if="active == '9'" class="detailsbox">
        <div class="breadcrumb">
          <div>
            产品介绍<span
              ><i class="el-icon-arrow-right"></i>{{ detailsData.title }}</span
            >
          </div>
        </div>
        <div class="details">
          <div class="top">
            <img :src="detailsData.url" alt="" />
            <div>
              <h5>{{ detailsData.title }}</h5>
              <p class="price">￥{{ detailsData.price }}</p>
              <p>发行方：易链数博</p>
              <p>发行量：{{ detailsData.num }}</p>
              <p class="bank">剩余：{{ detailsData.bank }}</p>
              <span>每个账号限购5个</span>
            </div>
          </div>
          <img class="imgs" :src="detailsData.details" alt="" />
        </div>
      </div>
	  
	  <div v-else-if="active == '11'" class="company">
	   <div class="login">
	           <div class="login-wrap">
	               <h3>登录</h3>
	               <input type="text" placeholder="请输入用户名" v-model="username">
	               <input type="password" placeholder="请输入密码" v-model="password">
	               <button v-on:click="login">登录</button>
	               <span v-on:click="ToRegister">没有账号？马上注册</span>
	           </div>
	    </div>
	  </div>
	  
	  <div v-else-if="active == '12'" class="company">
	     <div class="login">
	          <div class="login-wrap">
	              <h3>注册</h3>
	              <input type="text" placeholder="请输入用户名" v-model="newUsername">
	              <input type="password" placeholder="请输入密码" v-model="newPassword">
				  <input type="text" placeholder="请输入手机号" v-model="newUsername">
				  <input type="text" placeholder="请输入真实姓名" v-model="newUsername">
	              <input type="text" placeholder="请输入身份证号码" v-model="newUsername">
				  <button v-on:click="">注册</button>
				  <span v-on:click="">注册后，系统会根据【手机号、姓名、身份证号】做实名认证！！！</span>
	          </div>
	    </div>
	  </div>
	  
      <div v-else-if="active == '10'" class="footerDatabox">
        <div class="content">
          <div class="menu">
            <div
              v-for="(item, i) in footerList"
              :key="i"
              @click="clickUpper(item, i)"
              :class="footeractive == i ? 'act' : ''"
            >
              <i class="el-icon-caret-right" v-if="footeractive == i"></i>
              <span>{{ item }}</span>
            </div>
          </div>
          <div v-if="footeractive == '0'">
            <h2>川渝数藏数字藏品平台用户服务协议</h2>
            <p class="notbold">
              《川渝数藏数字藏品平台用户服务协议》（以下简称“本协议”）是由您与易链数博科技文化有限公司（作为运营方）（以下统称“我们”）就我们向您提供数字藏品平台服务（以下简称“本服务”）所订立的有效合约。请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有任何疑问的，请勿进行下一步操作。您可通过易链数博科技文化有限公司运营的官方渠道进行咨询，以便我们为您解释和说明。
            </p>
            <h5>【特别提醒】</h5>
            <p class="notbold mag">
              您需审慎阅读、充分理解本协议的各个条款，尤其是以粗体并下划线标示的条款，包括第三条的数字藏品购买和转赠规则、第四条的平台服务说明及使用规范、第五条用户违约及处理措施、第八条免责事项、第十条的法律适用和争议解决以及附件一的《违规用户处罚规则》等可能影响您权利和责任的相应条款。
            </p>
            <p class="notbold mag">
              您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，知晓并理解本协议所有条款的含义且愿意承担相应的法律后果。本协议视为已于您在线点击确认或以其他方式签署本协议之日有效签署，对各方成立并生效。
            </p>
            <p class="notbold mag">
              我们关注且重视未成年人权益的保障。若您未满十八周岁，请在监护人的陪同下阅读和判断是否同意本协议。您点击确认或其他类似按钮并继续使用本服务即视为您已经取得监护人的必要同意。
            </p>
            <h4>一、定义</h4>
            <p class="notbold mag">
              1.1
              川渝数藏平台或平台：指我们开发运营的数字藏品发行、信息展示和销售的平台。
            </p>
            <p class="notbold mag">
              1.2数字藏品：指使用华为区块链技术进行唯一标识的经数字化的特定虚拟作品、艺术品和商品，包括但不
              限于数字图片、音乐、视频、3D模型等各种形式的虚拟数字藏品。
            </p>
            <p class="notbold mag">
              1.3发行方：指在平台发行数字藏品并授权发售方销售数字藏品的实体或个人。如果您的数字藏品是通过与川渝数藏平台合作的第三方平台兑换、购买或获得，并在川渝数藏平台进行展示、应用、使用或获得服务的，则发行方指在该第三方平台发行数字藏品的实体或个人。
            </p>
            <p class="notbold mag">
              1.4
              发售方：是指经平台的发行方授权，通过平台向您销售数字藏品并进行收款和开票的实体。在本协议中特指川渝数藏平台。
            </p>
            <p class="notbold mag">
              1.5服务∶指我们以及我们的关联方和合作方基于互联网和区块链，以包含数字藏品平台在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务，包括但不限于以下服务∶（i）数字藏品
              的展示、浏览、购买、转赠、争议处理、订单管理等相关的信息技术服务（ii）数字藏品的应用场景相关的应用服务以及（iii）将数字藏品在华为链进行存证、管理、薄记及交收等存管服务
            </p>
            <p class="notbold mag">
              1.6平台规则：指已在或后续将在平台发布的各类规则、规范、公告、说明、解读、声明、指引或其他类似内容，具体以平台实际展示为准。
            </p>
            <p class="notbold mag">
              1.7应用场景：指平台之外的提供数字藏品信息展示、交互和其他应用活动的各类App、小程序和网页等应用软件。
            </p>
            <h4>二、账户注册、管理和注销</h4>
            <p class="notbold mag">
              2.1
              在您通过平台注册或使用本服务之前，您应确保您具备与使用本服务及购买、转赠数字藏品等相适应的民事行为能力，并有能力承担对应的法律后果和法律责任。如果您是未成年人，您在平台注册账户、转赠数字藏品或进行其他行为，应获得您监护人的同意。
            </p>
            <p class="notbold mag">
              2.2
              账户注册∶您应当按照平台页面的提示直接提供您的手机号，开立和注册您的平台账户。当您成功完成注册程序后，您即取得平台账户并成为平台用户。在平台一个手机号仅能注册一个账户。为保障您的账户安全，您同意按照平台的要求及相关法律法规规定完成账户的实名认证。
            </p>
            <p class="notbold mag">
              2.3
              账户登入：您可通过您的账户名称（即手机号）和手机验证码、或平台允许的其他方式登录平台并使用本服务。
            </p>
            <p class="notbold mag">
              2.4
              账户管理∶您的账户仅限于您本人使用。除法律法规或平台规则另有规定，您不得将您的账户以任何方式进行转让、出借或出售。请您务必妥善保管账户登录名、短信校验码等信息。在您的账户登录状态下进行的所有操作、发出的所有指令均视为您本人做出。如果因您自身的原因造成您的账户被冒用、盗用或非法使用的，我们不承担由此造成的风险和您的损失。
            </p>
            <p class="notbold mag">
              2.5
              账户的限权∶我们有权依据本协议约定或者法律法规的规定对您的账户进行限权或关闭。在限权或关闭账户之前，我们将通知您对账户内的数字藏品进行处置。
            </p>
            <p class="notbold mag">
              2.6
              账户注销∶您可以申请注销您的账户。在申请注销账户之前，您需对账户内的数字藏品进行处置，包括在符合法律法规和平台规则的情况下将数字藏品转移至另一账户。如果在您发起注销账户申请并授权我们处置账户之日起六十日之内未能主动对账户内的数字藏品进行处置的，视为您同意自愿放弃对上述数字藏品包括所有权在内的一切权益。我们将在六十日届满之日对数字藏品进行处置。如您在发起注销账户申请之日起的六十日之内再次登陆账户的，视为您撤回上述注销账户的申请。请您注意账户一旦成功注销将不可恢复，即使您在注销后以同一手机号再次在平台注册账户，该账户也将视为新的用户账号，您在原注销账户内的数字藏品将无法被找回。因此请您谨慎操作。
            </p>
            <h4>三、数字藏品购买和转赠规则</h4>
            <p class="notbold mag">3.1数字藏品的购买∶</p>
            <p class="notbold mag">
              3.1.1
              您通过平台购买或兑换数字藏品，应当是已年满18周岁的中国大陆居民，并且已经通过平台的实名认证流程。如果您未满18周岁，将无法购买或兑换数字藏品。
            </p>
            <p class="notbold mag">3.2 数字藏品的转赠∶</p>
            <p class="notbold mag">
              3.2.1
              在平台规则或平台功能允许的情况下，您可以将所持有的数字藏品无偿转赠给平台的其他用户。您理解并同意，一旦发起转赠操作将无法撤销，且转赠成功后，与数字藏品相关的权利将同步且毫无保留地转移至受赠人。
            </p>
            <p class="notbold mag">
              3.2.2 您通过平台转赠数字藏品，应当满足以下必要的条件∶
            </p>
            <p class="notbold mag">
              （1）您与受赠人均为中国大陆居民身份且已年满18周岁；
            </p>
            <p class="notbold mag">（2）您与受赠人均已通过平台的实名认证；</p>
            <p class="notbold mag">
              （3）如果您是从数字藏品发行方处通过购买或兑换方式获得数字藏品的，您至少需要持有该数字藏品达到指定天数后才能转赠如果您是从转赠人处通过受赠方式获得数字藏品的，您应自受赠时起持有该数字藏品满指定天数后才能再次转赠。（具体时限可能不定期调整，且不同藏品时限可能不同，我们将在平台进行及时更新和公告，请您留意平台的公告信息和藏品属性）。
            </p>
            <p class="notbold mag">
              3.3
              您理解并确认，我们有权依据国家法律法规、监管政策、平台规则或业务需要对上述购买和转赠条件进行补充或修改。您与受赠人一旦点击页面“同意”按钮确认本协议或某一平台规则，或者发起购买或转赠申请，即表示您与受赠人承诺符合购买/转赠条件并同意受到本协议及平台规则的约束。
            </p>
            <h4>四、平台服务说明及使用规范</h4>
            <p class="notbold mag">4.1平台服务说明∶</p>
            <p class="notbold mag">
              4.1.1
              您有权在平台上享受数字藏品的浏览、购买、分享、转赠、争议处理、订单管理以及数字藏品的访问、欣赏、炫耀、信息查看等信息技术服务，部分数字藏品还可通过平台合作的应用场景进行使用，具体服务内容和功能以平台和应用场景页面向您实际展示的为准。
            </p>
            <p class="notbold mag">
              4.1.2
              您可以通过平台浏览自第三方平台的发行方处获取，或在本平台发行并授权由川渝数藏平台发售的数字藏品及其相关信息。当您在川渝数藏平台下单购买前，请您务必仔细确认所购买数字藏品的名称、介绍、发行方、价格、数量及/或其他限制性要求等重要事项，并对是否购买数字藏品做出独立决策和判断。一旦您提交订单，请在规定时限内完成支付。您支付成功后即视为您与川渝数藏平台之间就已支付价款部分的订单建立了合同关系。如果您未能在规定时间内完成支付或在支付完成后又退款成功的，我们将回收您订单项下的数字藏品。特别提醒本平台售出的数字藏品均为虚拟数字商品，而非实物，且因为区块链技术的特性，本平台售出的数字藏品不支持七天无理由退换。
            </p>
            <p class="notbold mag">
              4.1.3
              基于区块链技术的特性，数字藏品具有加密和不可篡改的特点。一旦上链，数字藏品元数据中的作者、创作时间和上链时间、购买者等信息，在链上无法篡改。您购买相关数字藏品后，您的购买
              者信息将记录在数字藏品的元数据中，作为您持有该数字藏品的权利凭证。
            </p>
            <p class="notbold mag">
              4.1.4
              数字藏品的图文展示、介绍、创作者等相关信息均由发行方提供，所有数字藏品的内容和版权由发行方承担责任。您应当对通过平台接触到的内容及其准确性、完整性和有效性自行审慎判断并承担由此引起的相应风险。除我们存在故意或重大过失外，我们不对您因前述风险而导致的损失或损害承担责任。
            </p>
            <p class="notbold mag">
              4.1.5
              为保障数字藏品发售交易和资金安全，您同意以平台认可的支付方式作为您购买数字藏品的支付工具。您同时授权平台认可的支付方式的提供商可以按照我们向您发出的指令为您提供资金结算和支付账户管理等服务。
            </p>
            <p class="notbold mag">
              4.1.6
              您理解并同意，数字藏品的版权由发行方或原作创作者拥有。除另行取得版权权利人书面同意外，您不得将数字藏品用于任何商业用途。除平台规则或平台功能允许外，您获得的数字藏品应仅用于自身收藏、学习、研究、欣赏和展示目的，不得用于任何其他目的。
            </p>
            <p class="notbold mag">
              4.1.7我们严格禁止您利用数字藏品进行售卖、炒作、场外交易、欺诈或以任何其他非法方式进行使用。我们保留随时根据业务发展和法律法规政策变化进行调整的权利。您认可并同意，为了保障交易安全、有序、合法以及履行我们反洗钱的义务，我们有权对您的区块链地址进行安全查询、加发现存在售卖、炒作、欺诈等异常行为，或您存在违反法律法规或本协议约定等情形，我们有权对您采取本协议第五条约定的必要措施。
            </p>
            <p class="notbold mag">4.2平台服务使用规范∶</p>
            <p class="notbold mag">
              4.2.1
              您承诺将始终遵守本协议以及与数字藏品相关的订单、平台规则以及我们不时更新且在平台公示的平台规则，包括但不限于本协议附件一规定的《违规用户处罚规则》等，并遵守平台或其他第三方服务提供商（如有）与您达成的相关协议或规则。
            </p>
            <p class="notbold mag">
              4.2.2
              您声明并保证，您对您在使用本服务及购买数字藏品的过程中所提供、发布的任何信息拥有完整、
              合法、有效的权利，并且符合相关法律法规及本协议的要求，不存在任何违反法律法规、国家政策以及其他违背社会公共利益、公共道德或妨害平台正常运行、侵犯他人合法权益的信息或行为。您同意我们有权对您提供或发布的信息内容进行审查，如发现违规或不适宜在平台展示的，我们有权进行删除或屏蔽处理。
            </p>
            <p class="notbold mag">
              4.2.3
              您应对通过本服务了解、接收或接触到的包括但不限于发行方、发售方或其他用户在内的任何个人信息和资料予以充分尊重。您不得擅自搜集、复制、存储、传播或以其他任何商业目的使用前述个人
              信息和资料，不得擅自使用什何第三方软件，硬件，插件资料，不得擅自使用任何第三方软件、硬件、插件、外挂、系统等查看、获取本服务中所包含的我们及我们的关联方、合作方或发行方、其他用户的任何相关信息、数据等内容。
            </p>
            <p class="notbold mag">
              4.2.4
              您理解，我们提供本服务有赖于平台系统的准确运行及操作。若出现系统差错、故障或其他原因引发了展示错误、您获得不当获利等情形的，您同意我们可以自行或通过我们的关联方、合作方采取更正
              差错、暂停服务、划扣款项等适当纠正措施。
            </p>
            <h4>五、用户违约及处理措施</h4>
            <p class="notbold mag">
              5.1
              您理解并同意，为了维护交易秩序和交易安全，如我们发现或合理判定您存在违法违规或违反本协议约定情形的，我们有权采取救济措施，包括但不限于∶
            </p>
            <p class="notbold mag">（1）要求您限期纠正违规或违约行为；</p>
            <p class="notbold mag">（2）主动执行关闭相关订单；</p>
            <p class="notbold mag">
              （3）屏蔽或限制您访问、使用数字藏品或限制您持有的数字藏品的某些功能；
            </p>
            <p class="notbold mag">（4）对违法违规信息进行删除或屏蔽）；</p>
            <p class="notbold mag">（5）暂停或终止向您提供部分或全部服务；</p>
            <p class="notbold mag">（6）回收您非法或违规获得的数字藏品；</p>
            <p class="notbold mag">
              （7）采取本协议附件一《违规用户处罚规则》规定的相关处罚措施；以及
            </p>
            <p class="notbold mag">（8）其他合理、合法的措施。</p>
            <p class="notbold mag">
              5.2
              如您的行为使我们及/或我们的关联方和合作方等遭受损失的，我们有权要求您赔偿我们的全部损失，包括商誉、支付的赔偿金、律师费、诉讼费等。
            </p>
            <h4>六、软件许可</h4>
            <p class="notbold mag">
              6.1
              我们授予您一项仅限于个人非商业目的、非排他性、可撤销、不可转让、不可分许可的有限的普通许可，您可以在本协议有效期内，按照本协议及平台规则限定的范围和方式使用川渝数藏平台服务。未经我们另行书面许可，你不得为商业目的下载、安装、登录和使用本软件。本协议未明示授权的其他一切权利仍由我们或相关权利方保留。
            </p>
            <p class="notbold mag">
              6.2
              我们会不断丰富您使用本软件的终端、内容和形式。您理解由于软件话配及终端限制，我们可能开发不同的软件版本。您应当选择与您的终端、系统等相匹配的软件版本，否则由此造成的本软件无法正常
              运行或您的终端、系统及其软硬件功能损害的，我们不承担责任.
            </p>
            <p class="notbold mag">
              6.3
              为了改善用户体验、完善服务内容、以及提高软件安全性等目的，我们有权对本软件及相关平台服务、功能、界面等进行更新、修改，包括开发新的功能、删除旧功能等，该等变更不视为我们对本协议的履行存在任何瑕疵，您同意不会因此追究我们的责任。您理解在本软件版本更新后，我们不保证旧版本的软件及其服务、功能等可以继续正常使用，请您随时关注并将本软件更新至最新版本。
              6.4 您应当从本软件的
            </p>
            <p class="notbold mag">
              6.4
              您应当从本软件的官方网站、官方应用分发平台及/或我们授权的其他第三方网站、应用分发平台等渠道获取本软件。如果您从非官方或未经我们授权的第三方获取本软件或与本软件名称相同的安装程序，我们无法保证该软件能够正常使用和运行，由此产生的任何损害或损失将由您自行承担。
            </p>
            <p class="notbold mag">
              6.5
              您仅可通过本人人工操作的方式使用本软件及相关平台服务，不得自行或允许第三方通过非官
              方程序、软件、硬件、机器、脚本、爬虫或其他自动化的方式使用本软件及相关平台服务或获取该等软件、内容和服务数据。
            </p>
            <h4>七、用户信息的保护及授权</h4>
            <p class="notbold mag">
              我们非常重视对您个人信息的保护，您同意我们依据本协议及《川渝数藏隐私权政策》向您收集为您提供本服务所需要的信息并进行合理使用、处理、存储和共享。我们将遵循合法、正当、必要和诚信、公开、
              透明的原则，依据适用法律法规及平台隐私权政策的要求严格保护您的个人信息。请您在使用本服务前仔
              细阅读《川渝数藏隐私权政策》，如有任何疑问请随时联系我们
            </p>
            <h4>八、免责事项</h4>
            <p class="notbold mag">
              8.1
              我们将努力保障您的合法权益。请您理解，在您购买数字藏品或使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议项下约定或您无法完成数字藏品交易的，我们将努力在第一时间与相关单位配合并及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任∶
            </p>
            <p class="notbold mag">
              （1）在与本服务相关的系统停机维护或升级期间；
            </p>
            <p class="notbold mag">
              （2）因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化
              和政府行为等不可抗力原因；
            </p>
            <p class="notbold mag">
              （3）您操作不当或通过非我们授权或认可的方式使用本服务；
            </p>
            <p class="notbold mag">
              （4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、电信设备故障等原因；
            </p>
            <p class="notbold mag">
              （5）因第三方服务瑕疵的原因（例如因苹果等第三方服务商的原因未能将您的支付信息及时传达平台，导致您的订单未能被平台识别为付款成功而因此购买失败）。
            </p>
            <p class="notbold mag">
              8.2
              您理解并确认，我们仅以“现状”向您提供本服务。您在确认接受本协议或使用本服务前已充分了解数字藏品平台的功能和服务特性并同意我们无须就软件瑕疵、功能不足或任何需改进事项对
              您承担任何除法律有明确规定外的责任。
            </p>
            <p class="notbold mag">
              8.3
              您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，而您不会因此追究我们的责任。
            </p>
            <p class="notbold mag">
              8.4
              为便于向您提供服务，我们可能在平台设置相应的外部链接。除法律另有明确规定外，我们不对外部链接的准确性、有效性、安全性和完整性作出承诺或保证。同时，对于该等外部链接指向的不由
              我们实际控制的任何网页或客户端上的内容，您同意我们将不承担相关责任。
            </p>
            <p class="notbold mag">
              8.5
              本协议项下涉及的所有数字藏品，由发行方保证对数字藏品享有作为权利人完整、合法、有效的权利或已取得权利人的合法授权，保证其有权开展数字藏品生成、发行、转赠等场景的应用活动，保证本协议下的数字藏品业务不违反任何法律法规或侵犯第三方的合法权益，且就在本平台发行的数字藏品，发行方保证有权授权川渝数藏平台进行发售。如因数字藏品发行方或原创作者侵权、发布危害国家利益、违反公序良俗以及其他可能产生严重负面舆情的言论或行为，或者数字藏品存在权利瑕疵、内容违法违规、或其他可能产生严重负面舆情等情形，导致数字藏品被屏蔽或限制使用的，您可以直接联系发行方协商解决，或者您也可以通过官方渠道联系我们的工作人员帮助协调处理。
            </p>
            <p class="notbold mag">
              8.6
              根据有关法律法规，川渝数藏平台任何用户经由平台以上载、张贴、发布或任何其它方式传送的文字、图片、个人形象、肖像、姓名、商标、服务标志、品牌、公司标记、视频、音频或其他信
              息（以下简称“内容”），均由内容提供者承担责任。川渝数藏平台不保证该等内容的正确性、完整性或品质。在任何情况下，川渝数藏平台不为任何内容负责，包括但不限于任何内容的错误或遗漏，以及经由本平台发布的任何内容而衍生的损失或损害。
            </p>
            <p class="notbold mag">
              8.7
              华为链仅就数字藏品在华为链上的信息存证和唯一标识提供区块链技术服务。
            </p>
            <h4>九、知识产权等相关权利</h4>
            <p class="notbold mag">
              9.1
              平台上的所有内容，包括文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由我们或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
            </p>
            <p class="notbold mag">
              9.2非经我们或其他权利人书面同意，您不得擅自使用、修改、反向编译、复制、获取、公开传播、改变、散布、发行或公开发表、转让、许可、商业利用平台的相关软件、内容以及其他我们或权利人享有的知识产权，否则我们有权终止本协议，您需对您的违约或侵权行为给我们或其他权利人造成的损
              失承担相应赔偿责任。
            </p>
            <p class="notbold mag">
              9.3
              在遵守平台规则的前提下，您可以在平台上发布与数字藏品有关的原创内容。您应保证您是原创内容的著作权人或已取得合法授权进行发表，并且该内容不会侵犯任何第三方的合法权益或含有违法、违规或不良信息。如果第三方提出异议或平台根据事实判断您发表的内容构成侵权或含有违法、违规或不良信息的，平台有权采取删除或屏蔽相关的内容的措施，并且有权追究您的法律责任。
            </p>
            <p class="notbold mag">
              9.4
              我们建议您对于发表的原创内容自行进行不定期备份。您理解并同意，平台无法为您发表的内容
              信息提供永久存储服务。如果您停止使用本服务或本服务被终止或取消的，我们无法向您返还或提
              供您发表的内容信息或相关数据。
            </p>
            <p class="notbold mag">
              9.5
              您对使用本服务的行为以及通过平台发布和公开的信息享有合法权利，并承担相应法律责任。您同意我们对您在平台发布和公开的内容享有使用权，您同意授予我们和我们的关联方及合作方一项全球范围内的、免费的、可分许可的、非独家的使用许可权利，可以在川渝数藏平台以及其他渠道和应用程序上（包括但不限于各公众号、生活号）以宣传推广的目的使用上述您发布和公开的内容。
            </p>
            <p class="notbold mag">
              9.6
              您同意未经我们事先书面同意，您不得自行或授权、协助任何第三方采用程序化或非正常浏览
              等技术手段非法抓取任何用户在平台发布的内容信息和数据。
            </p>
            <p class="notbold mag">
              9.7
              您同意并授权我们可以以自己的名义或委托第三方专业机构就侵犯您在平台发表的享有合法权益的内容的行为（包括但不限于私自复制、使用、抄袭等）进行采取相应的法律行动，包括但不限于监测侵权行为、发送维权函、提起诉讼或投诉等。
            </p>
            <p class="notbold mag">
              9.8
              为提高您使用我们服务的感受和满意度，您同意授权平台可以对您使用平台的数据进行调查研究和分析，从而进一步优化平台的服务。
            </p>
            <p class="notbold mag">
              9.9在您通过平台发布任何内容时，您同意将始终遵守《川渝数藏平台用户使用规则》的约定。平台有权但无义务对您发布的内容进行审核，有权根据相关法律法规及《川渝数藏平台用户使用规则》
              对您发布的违法、违规或不良信息进行处置。
            </p>
            <p class="notbold mag">
              9.10如您在使用我们服务的过程中发现其他用户上传违法、违规或侵权等内容，您可直接点击"举报"按键
              进行举报，相关人员会尽快核实并根据《川渝数藏平台用户使用规则》进行处理。如您认为上述方法无法解决问题或有必要向司法行政机关寻求帮助的，您也可向相关机关反馈，平台将依法配合司法机关的调查取证工作。
            </p>
            <h4>十、其他</h4>
            <p class="notbold mag">
              10.1为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。我们会以公告、客户端信息推送、发送系统消息等通知方式（统称“通知”）就协议的更新向您进行提示。除通知中另有说明外，更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您需停止使用相关服务。若您继续使用本协议项下相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束；双方协商一致，也可另行变更相关服务和对应条款内容。
            </p>
            <p class="notbold mag">
              10.2
              您理解并同意，出于本服务的业务特性，未经我们书面同意，您不得向第三方转让本协议项下的权利和义务。您进一步同意，在不对您使用本服务造成不利影响的前提下，我们可将在本协议项下的权利
              和义务全部或部分转让给我们的关联方而无需另行通知或征得您的同意。
            </p>
            <p class="notbold mag">
              10.3
              我们在平台页面展示的最新服务说明、平台规则等构成本协议不可分割的一部分。如果条款之间存在
              不一致之处，以（1）服务说明、平台规则；（2）本协议的先后顺序进行适用
            </p>
            <p class="notbold mag">
              10.4
              本协议的部分条款无论因何种原因部分无效或不可执行，其余条款仍有效并对双方仍具有约束力。
            </p>
            <p class="notbold mag">
              10.5本协议于四川省市高新区签订。本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的民事争议，均应提交本协议签订地成都市高新区人民法院管辖。
            </p>
            <h4>附件一《违规用户处罚规则》</h4>
            <p class="notbold mag">
              为维护数字藏品平台的正常运营，保障各方的合法权益，严格防范和打击任何形式的转售、炒作数字藏品的违法违规行为，依据《川渝数藏数字藏品平台用户服务协议》制定本规则。
            </p>
            <p class="notbold mag">
              1.
              如果我们收到投诉或举报，或通过其他途径，经过合理判断认定您从事了以下违法、违规或违约行为的，我们有权根据您的具体行为和情节实施下述处罚措施，并向您发送处罚通知。（注：行为-具体情形处罚措施按序对应）
            </p>
            <p class="notbold mag">1.1行为：</p>
            <p class="notbold mag">1）转售数字藏品</p>
            <p class="notbold mag">2）购买数字藏品</p>
            <p class="notbold mag">3）组织数字藏品场外交易</p>
            <p class="notbold mag">4）利用外挂抢购数字藏品</p>
            <p class="notbold mag">5）违规使用数字藏品</p>
            <p class="notbold mag">6）发布违规或不当信息</p>
            <p class="notbold mag">7）违法犯罪行为</p>
            <p class="notbold mag">8）其他违约行为</p>
            <p class="notbold mag">1.2具体情形：</p>
            <p class="notbold mag">
              1）任何形式有偿转售数字藏品的行为，包括但不限于在任平台、网站、社交媒体等发布转售信息、转售要约或言论的行为。
            </p>
            <p class="notbold mag">
              2）任何形式有偿收购数字藏品的行为，包括但不限于在任何平台、网站、社交媒体等发布收购信息、收购要约或言论的行为。
            </p>
            <p class="notbold mag">
              3）组织数字藏品的场外交易行为，包括但不限于在任何平台、网站、社交媒体通过拍卖、担保交易、发布交易等形式组织或撮合数字藏品场外交易或为其提供便利存易。
            </p>
            <p class="notbold mag">
              4）利用外挂（包括通过非官方程序、软件、硬件、机器、脚本、爬虫或其他抢自动化的方式）抢购数字藏品的行为，出售外挂工具或提供代抢服务的。
            </p>
            <p class="notbold mag">
              5）违规使用数字藏品，包括但不限于超过发行方的授权范围使用数字藏品。
            </p>
            <p class="notbold mag">
              6）发布违规或不当信息，损害链数字藏品平台或他人合法权益。
            </p>
            <p class="notbold mag">
              7）构成违法犯罪的，包括但不限于欺诈、洗钱、非法经营等。
            </p>
            <p class="notbold mag">
              8）其他违反《川渝数藏数字藏品平台用户服务协议》、《数字藏品购买和转赠须知》、平台规则的行为。
            </p>
            <p class="notbold mag">1.3处罚措施</p>
            <p class="notbold mag">
              1）针对首次违规的，1年内关闭您账户的数字藏品转赠功能；针对重复违规或恶意违规的，酌情加
              重处罚，包括永久关闭您账户的数字藏品转增功能，或者永久封禁账号。
            </p>
            <p class="notbold mag">
              2）针对首次违规的，1年内关闭您账户内数字藏品的转赠功能；针对重复违规或恶意违规的，酌情加重处罚，包括永久关闭您账户的数字藏品转赠功能，或者永久封禁账号。
            </p>
            <p class="notbold mag">
              3）视具体情节永久关闭您账户内数字藏品的转赠功能或永久封禁账号；构成犯罪的，报警并移交司法机关处理。
            </p>
            <p class="notbold mag">
              4）视具体情节限制您名下账户的数字藏品购买功能和/或转赠功能；构成犯罪的，封禁账号、报警并移交司法机关处理。
            </p>
            <p class="notbold mag">
              5）限制您对外分享数字藏品，并可视具体情节限制您购买数字藏品。
            </p>
            <p class="notbold mag">
              6）限制您对外分享数字藏品，并可视情节1年内关闭您账户的数字藏品转赠功能。
            </p>
            <p class="notbold mag">7）封禁账号、报警并移送司法机关处理。</p>
            <p class="notbold mag">
              8）依据《华为链数字藏品平台用户服务协议》、《数字藏品购买和转赠须知》、平台规则酌情处罚。
            </p>
            <p class="notbold mag">
              2.
              如您同时涉及前述多项违反情形的，或者您重复违规或恶意违规的，我们有权分别采取多项对应的处罚措施，或有权按照从重处罚原则对您的相关行为予以处罚。
            </p>
            <p class="notbold mag">
              3.
              针对他人对您的投诉和举报，或我们对您的处罚措施，您可以提交相关证明材料进行申诉，我们将依据事实进行处理。
            </p>
            <p class="notbold mag">
              4.您理解并确认，我们有权依据国家法律法规、监管政策、或风险防范的需要对上述处罚规则进行修改、
              调整、补充和公示。
            </p>
          </div>
          <div v-else-if="footeractive == '1'">
            <h2>川渝数藏数字藏品平台用户使用规则</h2>
            <p class="notbold">
              四川易链数博科技文化有限公司（作为运营方）（以下统称“我们”通过川渝数藏数字藏品平台（以下简称“平台”）向您提供数字藏品的展示、浏览、购买、转赠以及线上展馆创建、用户评论等信息技术服务、应用服务和存管服务。为了规范和维护平台运营秩序，根据《川渝数藏数字藏品平台用户服务协议》，特制定并颁布本规则。用户在使用平台服务时，应遵守本规则的相关规定。
            </p>
            <h4>一、 不得利用平台从事违法违规或不当行为</h4>
            <p class="notbold mag">
              为了营造积极、健康的互联网生态环境，用户在平台发布的内容应当符合当代社会主流价值观。如您在使用平台内的服务时，涉嫌存在违法违规等行为，我们有权基于风险控制需要，停止向您提供相应服务。根据《中华人民共和国网络安全法》《互联网信息服务管理办法》、《网络信息内容生态治理规定》等法律法规，您不得利用平台提供的服务从事违法违规行为，包括但不限于：
            </p>
            <p class="notbold">（一）不当行为</p>
            <p class="notbold mag">
              1.涉嫌侵扰用户安宁、造成用户使用体验减损或者影响用户正常使用的骚扰行为（如以评论、私信、求关注等方式向他人反复发送重复、类似、诉求相同的信息，对其他用户造成干扰的）；
            </p>
            <p class="notbold mag">
              2.用诅咒、夸张或营销、诱导等性质言语来胁迫、引诱或煽动用户分享、关注、点赞、评论、发布广告或将用户引导至其他平台或个人；
            </p>
            <p class="notbold mag">
              3.设置的昵称、头像、签名或简介等违反法律法规或违背社会公德、公序良俗；
            </p>
            <p class="notbold mag">
              4.发布含有人身攻击或其他具有欺骗、诱导从事危险行为或不文明行为的内容；
            </p>
            <p class="notbold mag">
              5.涉嫌通过恶意注册账号、刷单、炒作、虚假交易或评论、虚拟币交易、场外交易、使用外挂等形式，进行数据造假或实施其他作弊行为（如虚假的粉丝关注、点赞、评论等）；或者
            </p>
            <p class="notbold mag">
              6.其他违反法律法规、国家政策以及违背社会公共利益、公共道德或妨害平台正常运行、侵犯他人合法权益的行为。
            </p>
            <p class="notbold mag">
              （二）制作、复制、发布含有以下违法违规内容的信息
            </p>
            <p class="notbold mag">1.反对宪法所确定的基本原则的；</p>
            <p class="notbold mag">
              2.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            </p>
            <p class="notbold mag">
              3.损害国家荣誉和利益的，如损害国旗国徽形象；
            </p>
            <p class="notbold mag">
              4.歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
            </p>
            <p class="notbold mag">
              5.宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
            </p>
            <p class="notbold mag">
              6.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            </p>
            <p class="notbold mag">
              7.煽动民族仇恨、民族歧视，破坏民族团结的；
            </p>
            <p class="notbold mag">
              8.破坏国家宗教政策，宣扬邪教和封建迷信的；
            </p>
            <p class="notbold mag">9.散布谣言，扰乱经济秩序和社会秩序的；</p>
            <p class="notbold mag">10.侮辱或者诽谤他人，侵害他人合法权益的；</p>
            <p class="notbold mag">
              11.攻击诋毁党和国家领导人，捏造领导人负面信息，滥用领导人形象的；
            </p>
            <p class="notbold mag">
              12.涉及违禁物品（如枪支、毒品、弹药、虚拟货币等）、违禁活动（如传销、洗钱、售假、黑社会等）或欺诈、赌博等违法行为的；或者
            </p>
            <p class="notbold mag">
              13.其他违反法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等要的。
            </p>
            <p class="notbold mag">
              （三）制作、复制、发布含有以下不良内容的信息
            </p>
            <p class="notbold mag">
              1.涉及未成年人隐私或危害未成年人安全和健康的相关内容，包括但不限于引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等；
            </p>
            <p class="notbold mag">
              2.散布淫秽、色情内容或者涉及色情擦边、性暗示类信息的；
            </p>
            <p class="notbold mag">
              3.宣扬暴力、恶意谩骂，包括但不限于展现血腥、惊悚、残忍等致人身心不适的；
            </p>
            <p class="notbold mag">
              4.危害系统和网络安全的，包括含有钓鱼网站、病毒、文件、计算机代码或程序等信息；
            </p>
            <p class="notbold mag">
              5.炒作绯闻、丑闻、劣迹等或宣扬低俗、庸俗、媚俗内容的；
            </p>
            <p class="notbold mag">
              6.涉及中奖类、虚假夸大、假冒伪劣等不实或可疑内容的；
            </p>
            <p class="notbold mag">
              7.不当评述自然灾害、重大事故等灾难，或者煽动人群歧视、地域歧视等的；或者其他对网络生态造成不良影响的内容。
            </p>
            <p class="notbold mag">
              （四）制作、复制、发布含有以下侵权内容的信息
            </p>
            <p class="notbold mag">
              1.擅自使用他人已经登记注册的企业名称或商标，侵犯他人企业名称权及商标专用权；
            </p>
            <p class="notbold mag">
              2.擅自使用他人姓名、肖像，侵害他人姓名权、肖像权等合法权益；
            </p>
            <p class="notbold mag">
              3.冒用身份，使用他人特征性信息，足以误导公众的；
            </p>
            <p class="notbold mag">
              4.未经授权发布他人原创文章或作品，侵犯他人知识产权等合法权益的；
            </p>
            <p class="notbold mag">
              5.未经授权发布他人身份证号码、联系方式、家庭住址、照片等个人隐私资料，侵犯他人肖像权、隐私权等合法权益的；
            </p>
            <p class="notbold mag">
              6.侮辱或者诽谤他人，侵害他人名誉等合法权益的；
            </p>
            <p class="notbold mag">
              7.未经授权发布企业商业秘密，侵犯企业合法权益的；或者
            </p>
            <p class="notbold mag">8.其他侵犯他人合法权益的行为。</p>
            <h4>二、违规行为处罚</h4>
            <p class="notbold mag">
              我们将全力保障您的权益，并不断努力为您提供更好的服务。为了保障您与他人的合法权益，在下列情况下平台将根据您的行为风险程度采取必要措施，包括但不限于（i）警告，（ii）删除您发布的相关信息，（iii）禁言，（iv）临时或永久地暂停或关闭您账户的部分功能，例如关闭评论/回复功能、禁止修改个人资料、禁止创建新展馆主题、禁止关注/点赞、禁止被推荐等，（v）封禁账号，和/或（vi）停止向您提供部分或全部服务：
            </p>
            <p class="notbold mag">1. 您违反本规则约定的内容；</p>
            <p class="notbold mag">
              2.
              您违反您与我们签署的《川渝数藏数字藏品平台用户服务协议》、以及平台其他规则的任何条款；
            </p>
            <p class="notbold mag">
              3.
              基于法律法规及政策的规定、监管机构要求或风险管控的需要；或者其他您存在涉嫌违反国家法律法规的行为或从事侵害他人合法权益的行为。
            </p>
            <p class="notbold mag">
              当我们发现或收到第三方举报或投诉后认定您存在上述情形的，我们将采取相应的处置措施。但对您采取的上述处置措施并不排除我们有权依据《川渝数藏数字藏品平台用户服务协议》以及平台其他规则对您同时实施其他类型的处罚。如果您对我们实施的处罚措施有异议的，您可以联系客服提交相关证明材料进行申诉，我们将依据事实进行处理。
            </p>
            <h4>三、创作者账号认证</h4>
            <p class="notbold mag">
              为了向平台内数字藏品的创作者更好地提供服务，提高创作者账号信息的真实、合法、有效性，我们特此对创作者账号提供以下官方认证服务。
            </p>
            <p class="notbold mag">
              1．官方认证分为个人认证和机构认证。在您作为创作者的情况下，可以以个人身份申请个人认证，或经所在企业授权以机构身份申请机构认证。目前认证服务仅向平台邀请的符合资格的特定创作者开放。
            </p>
            <p class="notbold mag">
              2．完成官方认证后，您将享有认证用户的相关权利，包括但不限于在平台上对外展示您账号的官方认证标识。
            </p>
            <p class="notbold mag">
              3．您可以自主选择是否申请认证或取消认证。您理解每个账号在同一时间只能处于一种认证状态，即未认证、个人认证或机构认证。您的账号认证状态以平台实际展示为准。您理解一旦您取消认证成功，该账号将不再展示认证相关信息且不再享有认证相关权利，因此请您谨慎操作。
            </p>
            <p class="notbold mag">
              4．您同意如您申请官方认证，需要向我们提供认证所需的必要信息和资料，包括您的账号认证类型、认证称号以及平台提示的其他认证资料。
            </p>
            <p class="notbold mag">
              5．您的认证行为应当符合本规则以及《川渝数藏数字藏品平台用户服务协议》的规定。您将对通过您的账号实施的行为和言论、或发布的信息和内容独立承担责任，无论您的账号是否已通过认证。平台官方认证服务仅限于对创作者账号运营主体提交的资料及信息进行合理、谨慎的形式审查。通过了平台官方认证并不代表我们对该账号主体的真实性、合法性和有效性等做出任何担保、承诺或保证。
            </p>
            <h4>四、其他</h4>
            <p class="notbold mag">
              您理解并同意，我们可能会根据相关法律法规、监管政策或风险防范的需要对本规则不时进行修改、调整、补充和公示。如果您不同意本规则的该等更新，请勿使用平台的服务。您如果继续使用平台的服务将视为您同意并遵守更新的规则。
            </p>
          </div>
          <div v-else-if="footeractive == '2'">
            <h2>免责声明</h2>
            <p class="date">本声明最近更新日期：2023年7月25日</p>
            <p class="user">
              尊敬的川渝数藏文化旅游数字资产交易平台（以下统称“本平台”）的用户：
            </p>
            <p class="relief">
              本平台开发者及运营方四川易链数博科技文化有限公司向您提示：您在访问或使用本平台时，需要注意以下免责事项：
            </p>
            <p>
              一、
              您使用本平台提供的服务或信息之前，应当根据本平台《用户注册协议》的规定，进行用户注册，并根据部分服务的要求进行身份实名认证。如您未进行前述用户注册及身份实名认证，则您在使用本平台提供的服务或信息时，所产生的一切现有的或未来可能发生的风险或损失，与本平台及我们无关，由您自行承担。
            </p>
            <p>
              二、
              如您向本平台提供的信息或资料不真实而导致账号、账号内财产等丢失、减少、无法找回的，本平台及我们不承担任何法律责任。
            </p>
            <p>
              三、
              您应妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，我们不承担补偿责任。
            </p>
            <p>
              四、
              本平台中可能存在由其他第三方提供的外部链接，请您谨慎点击使用。我们不保证该等外部链接的准确性和完整性，该外部链接指向的不由我们实际控制的任何网页上的内容，我们对其合法性亦概不负责，亦不承担任何法律责任，网页内所有内容亦不表明我们的观点或意见。
            </p>
            <p>
              五、
              我们并不介入任何用户与用户之间或第三方供应商与用户的交易，亦不对任何该等交易进行担保。如其它用户或第三方供应商提供的产品或服务不符合保障人身、财产安全的要求，或者侵害了您的合法权益，您可以按照本平台公布的我们的联系方式对相关用户及供应商进行举报，我们将在法律允许的范围内采取适当的处理措施。但您应了解，我们对该等纠纷不具有最终裁判的权利，必要时，我们可能要求您提供生效判决等合法依据方可采取相关措施。
            </p>
            <p>
              六、
              我们致力于向用户提供优质服务，但我们不保证本平台提供的产品、服务或其他材料符合所有用户的期望。
            </p>
            <p>
              七、
              因信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，战争，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，政府行为，司法行政机关的命令等不可抗力因素或第三方原因带来的风险与损失，本平台及我们无须承担责任。
            </p>
            <p>
              八、
              您在接受本平台的服务时，可能面临包括但不限于交易价格风险、市场风险以及作品差异风险等风险，均可能给您带来损失，上述风险及由此造成的损失，须由您自行承担。
            </p>
            <p>
              九、
              凡以任何方式访问、登录本平台或直接、间接接受本平台所提供的服务者，均视为自愿接受本免责声明的约束。
            </p>
            <p>十、 四川易链数博科技文化有限公司对本免责声明享有最终解释权。</p>
          </div>
          <div v-else-if="footeractive == '3'">
            <h2>川渝数藏数字藏品平台隐私政策</h2>
            <p class="notbold">概述</p>
            <p class="notbold relief">本政策将帮助您了解以下内容∶</p>
            <p class="mag">一、我们如何收集和使用您的个人信息</p>
            <p class="mag">二、我们如何使用Cookie、Beacon、Proxy等技术</p>
            <p class="mag">三、我们如何存储您的个人信息</p>
            <p class="mag">四、我们如何保护您的个人信息</p>
            <p class="mag">五、我们如何对外提供个人信息</p>
            <p class="mag">六、您如何实现您的个人信息权利</p>
            <p class="mag">七、对第三方责任的声明</p>
            <p class="mag">八、我们如何保护未成年人的信息</p>
            <p class="mag">九、本政策的适用和更新</p>
            <p class="mag">十、本政策中关键词说明</p>
            <p class="notbold">
              川渝数藏是由四川易链数博科技文化有限公司（作为开发者及运营方）（以下合称“我们”）通过下述途径向用户（以下简称“您”）提供的各项产品/服务包括但不限于平台等客户端以及开放平台账号或小程序等。我们深知个人信息对用户（“您”）的重要性
              我们非常注重保护您的个人信息及隐私，我们希望通过本隐私权政策（以下简称∶“本政策”）向您介绍在使用我们的服务时，我们将如何处理您的个人信息以及我们为您提供的访问、更正、删除和保护这些信息的方式。本政策与您使用我们的产品/服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。对本政策中与您的权益存在重大关系的条款和个人信息，我们将在正文中以粗体字和/或下划线进行标注以提示您注意。
            </p>
            <p class="notbold mag">其中要点如下∶</p>
            <p class="notbold mag">
              1.
              为了便于您了解在使用我们的服务时，我们需要收集的信息类型与用途，我们将结合具体服务向您逐一说明。
            </p>
            <p class="notbold mag">
              2.
              为了向您提供服务所需，我们会按照合法、正当、必要、诚信的原则收集您的信息。
            </p>
            <p class="notbold mag">
              3.
              如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监
              管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗
              提示等形式征得您的同意或确认第三方已经征得您的同意。
            </p>
            <p class="notbold mag">
              4.
              如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
            </p>
            <p class="notbold mag">
              5.
              您可以通过本政策介绍的方式访问和管理您的信息、设置隐私功能、注销川渝数藏账号或进行投诉举报。
            </p>
            <h4>一、我们如何收集和使用您的个人信息</h4>
            <p class="notbold mag">
              1.1根据相关法律法规及国家标准，我们可能在下列情形下依法处理您的个人信息∶
            </p>
            <p class="notbold mag">（1）取得您的同意；</p>
            <p class="notbold mag">（2）根据您的要求订立和履行合同所必需的；</p>
            <p class="notbold mag">
              （3）履行法定义务所必需的，如反洗钱、反恐怖融资、实名管理等相关法律法规要求的必要信息；
            </p>
            <p class="notbold mag">（4）与国家安全、国防安全直接相关的；</p>
            <p class="notbold mag">
              （5）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
            </p>
            <p class="notbold mag">
              （6）与犯罪侦查、起诉、审判和判决执行等直接相关的；
            </p>
            <p class="notbold mag">（7）所收集的个人信息是您自行公开的；</p>
            <p class="notbold mag">
              （8）从合法公开披露的信息中收集的个人信息，例如∶合法的新闻报道、政府信息公开等渠道；
            </p>
            <p class="notbold mag">
              （9）用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
            </p>
            <p class="notbold mag">（10）法律法规规定的其他情形。</p>
            <p class="notbold mag">
              需要注意的是，当你的个人信息处理属于前述第（2）至第（10）项情形时，我们可能会依据所适用法律法规的要求而无需再征得您的同意。
            </p>
            <p class="notbold mag">1.2帮助您成为我们的用户并提供账号服务</p>
            <p class="notbold mag">1.2.1账号的注册、登录</p>
            <p class="notbold mag">1、设立自审信息安全小组</p>
            <p class="notbold mag">
              为遵守法律法规的要求，您在注册并登录川渝数藏账号后才能使用评论、分享及其他用户服务，在未注册或登录账号的情况下您可以进行浏览、搜索。
            </p>
            <p class="notbold mag">
              账号的注册∶当您注册川渝数藏账号时，您需要提供手机号码以创建账号。手机号码是履行国家法律法规关于网络实名制要求的必要信息，如您未提供手机号码用于注册、登录，我们可能无法为您提供评论、分享等功能。同时为完善您的账号信息，您可以提供非注册必须信息（如头像、账号名、昵称、简介）来完善您的网络身份标识信息。当您使用编辑头像或上传图片功能时，我们在获得您的授权同意后将访问您的相册（存储）权限。
            </p>
            <p class="notbold mag">
              授权登录∶为了使您便捷的使用第三方账号登录并使用我们的产品/服务，我们会根据您的授权获取您在第三方平台的注册账号信息（头像、昵称、ID编号及您授权的其他信息），并与您的川渝数藏账号进行绑定。为了满足相关法律法规的网络实名制要求，您使用第三方平台账号首次授权登录时，我们需要获取您的手机号码并进行验证，验证成功后该手机号将与您的账号绑定。此类信息属于该功能的必要信息，若您不提供这类信息，您可能无法正常注册、登录。
            </p>
            <p class="notbold mag">
              请您注意，为向您提供便捷的登录及账号实名认证功能，您使用第三方账号进行授权登录时，我们
              将获取您的第三方账号头像、昵称、ID编号及您的身份证号码如您不愿向我们提供上述信息，您可以选择页面展示的其他账号注册/登录方式。
            </p>
            <p class="notbold mag">1.2.2账号实名认证</p>
            <p class="notbold mag">
              在您使用实名认证的功能或购买数字藏品等相关服务时，根据相关法律法规，您需要向我们和提供认证服
              务的第三方认证机构提供您的真实姓名、身份证号码、手机号码、面部特征（如需）等身份证明信息以完成验证。特别且必要情况下基于安全考虑，为了确保我们为您本人提供服务，我们可能会根据您提供的信息进行校验，将通过人脸识别的方式验证您的身份。当您使用实名认证功能时，我们在获得您的授权同意后将访问您的摄像头权限。身份证号码、电话号码、人脸信息属于您的敏感个人信息，您可以拒绝提供，此时您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
            </p>
            <p class="notbold mag">1.2.3账号找回</p>
            <p class="notbold mag">
              当您使用账号找回功能时，为保障您账号的安全所必需，在找回账号时我们可能需要您提供相关信息以进行验证，必要时可能通过实名验证、人脸识别等方式验证您的身份。
            </p>
            <p class="notbold mag">1.3帮助您购买或转赠数字藏品</p>
            <p class="notbold mag">
              1.3.1在您购买或转赠数字藏品时，根据区块链信息服务以及反洗钱相关要求，您需要完成账号实名认证。如您的账号未完成实名认证，您可能无法使用购买或转赠数字藏品的功能，但不影响您继续使用我们的其他服务。
            </p>
            <p class="notbold mag">
              1.3.2在您购买或转赠数字藏品时，我们会通过系统为您生成您的购买或转赠记录并上链存储。基于区块链技术的特性，数字藏品具有加密和不可篡改的特点，您购买或转赠相关数字藏品后，您的购买或转赠信息（交易时间、交易订单号）将进行加密处理并记录在区块链中，无法进行篡改或删除。您可以通过「我的-订单」查看和管理您的交易信息。
            </p>
            <p class="notbold mag">1.4信息发布</p>
            <p class="notbold mag">
              1.4.1当您使用发布动态、评论、点赞等功能时，我们将收集您发布的信息并公开展示您的昵称、头像、发布内容，您可自行删除您发布的信息。当您使用上传图片功能时，我们在获得您的授权同意后将访问您的相册（存储）权限。如您未开启相册权限，将无法使用上述功能，但您仍可正常使用我们的其他功能。
            </p>
            <p class="notbold mag">
              1.4.2当您使用您持有的数字藏品进行展馆创建时，我们将收集您发布的信息，
              并公开展示您的昵称、头像、发布内容（含展馆主题、展馆描述、展馆布置信息、布展藏品信息）。
            </p>
            <p class="notbold mag">
              1.4.3请您注意，您发布的内容中可能会包含您或他人的个人信息，请您在上传发布前，已确认获得合法授权或可按照法律法规所允许使用。
            </p>
            <p class="notbold mag">1.5互动交流</p>
            <p class="notbold mag">
              1.5.1当您关注您感兴趣的账号、藏品、话题，或您在进行浏览、评论、点赞、分享展馆等内容时，我们会收集您的浏览记录（您的关注、浏览）和点击操作记录（点击、搜索、点赞、关注、分享、评论、发布及
              有关行为），并向您展示您关注的相关内容。
            </p>
            <p class="notbold mag">
              1.5.2在您分享或接收被分享的信息、参加活动时（如炫耀或转赠数字藏品），我们需要访问您的剪
              贴板、相册，以便读取其中包含的口令、分享码、二维码、链接并实现跳转、分享等功能或服务。我们仅会对剪贴板、相册中的信息在您的设备本地端进行判断，不会将其中的信息回传至服务器。如您未开启相册权限，将无法使用上述功能，但您仍可正常使用我们的其他功能。
            </p>
            <p class="notbold mag">
              1.5.3请您注意，当您分享您的账号区块链地址、藏品哈希值、流转哈希值时，第三方可通过我们提
              供的区块链信息查询功能查看相应信息（如账号区块链地址持有的数字藏品信息，具体以页面展示
              为准）如您不希望他人知悉或查询您的信息，请勿公开发布或对外共享。
            </p>
            <p class="notbold mag">1.6消息通知</p>
            <p class="notbold mag">
              为向您提供服务，我们可能会使用您在使用产品及/或服务的过程中提供的您的联系方式（例如∶联系电话），以便向您发送消息告知、身份验证、安全验证、用户使用体验调研、争议处理等各类通知此外，我们也可能会通过向前述过程中收集的联系方式以短信、电话的方式，向您发送与我们服务、功能或活动相
              关的商业性信息如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
            </p>
            <p class="notbold mag">1.7用户服务及服务质量的改进</p>
            <p class="notbold mag">
              为了提升您的服务体验并改进我们的服务质量，或者为您推荐更优质或更适合的服务，我们会收集您使用我们服务的操作记录、您与客户服务团队联系时提供的信息及您参与问卷调查时向我们发送的信息。如您不提供前述信息，不影响您使用我们提供的其他服务。
            </p>
            <p class="notbold mag">1.8个性化推荐</p>
            <p class="notbold mag">
              1.8.1为了提升您的产品体验，向您推荐更为优质或适合的服务，我们可能会收集您在使用我们的产品/服务时的设备信息（包括1.9条所述设备信息）、浏览使用信息（点击、浏览、关注、分享、评论）、数字藏品购买记录，提取您的偏好特征，我们将根据用户信息形成群体特征标签，用于向您展示您可能感兴趣的数字藏品信息、账号、用户发布信息（动态、评论、展馆）、营销活动通知、商业性电子信息。
            </p>
            <p class="notbold mag">
              1.8.2您可通过「我的」-「设置」-「隐私设置」-「个性化内容推荐」对个性化内容推荐功能进行开启或关闭。当您选择关闭个性化内容推荐功能后，我们将基于内容热度、运营推荐等非个性化因素向您展示内容。
            </p>
            <p class="notbold mag">1.9安全管理</p>
            <p class="notbold mag">
              为了提高您使用我们产品/服务过程中的安全性，防止您的账号、资金、个人信息被不法分子获取，预防病毒、木马程序或其他恶意程序、网站，我们需要记录您使用的服务类别、方式及相关操作信息，例如∶设备品牌、设备型号、设备名称、IP地址、网络设备硬件地址（MAC地址）、网络类型、运营商信息、Wi-Fi状态/参数/列表、操作系统、设备软件版本信息、设备识别信息（如IMEI、Android
              ID、
              IDFA、OAID、IMSI、ICCID）、WLAN接入点（如SSID、BSSID）、所在地区、网络使用习惯以及其他与服务相关的日志信息。上述信息是我们用于维护产品/服务安全稳定运行的必要信息，如您不能提供
              前述信息，可能无法完成您所使用产品/服务的风控验证。
            </p>
            <p class="notbold mag">1.10基于系统权限为您提供的其他附加服务</p>
            <p class="notbold mag">
              1.10.1为向您提供更便捷、更优质、个性化的产品/服务，努力提升您的体验，我们在向您提供的以下附加
              服务中可能会通过开启系统权限的方式以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用我们提供的其他服务，但您可能无法获得这些附加服务给您带来的用户体验。您可在您的设备"设置”或我们客户端“我的-设置-通用设置-系统权限管理”中逐项查看上述权限的状态，并可自行决定这些权
              限的开启或关闭。
            </p>
            <p class="notbold mag">
              1.10.2请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
            </p>
            <p class="notbold mag">
              1.10.3您可点击查看《应用权限电请与使用情况说明》。
            </p>
            <p class="notbold mag">1.11其他</p>
            <p class="notbold mag">
              请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供
              及保护您的信息如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
            </p>
            <h4>二、我们如何使用Cookie、Beacon、Proxy等技术</h4>
            <p class="notbold mag">
              2.1为使您获得更轻松的访问体验，您访问川渝数藏网站或使用川渝数藏提供的产品/服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账号安全状态。这些数据文件可能是Cookie，Flash
              Cookie，您的浏览器或关联应用程序提供的其他本地存储（以下简
              称“Cookie”）。请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝川渝数藏的Cookie。多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新
              Cookie时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的Flash
              Cookie及类似数据。但这一举动在某些情况下可能会影响您安全访问川渝数藏网站和使用川渝数藏提供的服务。
            </p>
            <p class="notbold mag">
              2.2我们网站上还可能包含一些电子图像（以下简称“网络Beacon”），使用网络Beacon可以帮助网
              站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您浏览网页活动的信息（您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定）。
            </p>
            <p class="notbold mag">
              2.3如您通过我们的网站或APP，使用了由第三方而非川渝数藏提供的服务时，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术（以下简称“Proxy
              技术”）。使用Proxy技术，可以帮助您识别到我们已知的高风险站点，减少由此引起的钓鱼、账号泄露等风险，同时更有利于保障您和第三方的共同权益，阻止不法分子篡改您和您希望访问的第三方之间正常服务内容，包括∶不安全路由器、非法基站等引起的广告注入、韭法内容篡改。在此过程中，我们也可能会获得和保存您设备的IP地址、硬件ID以及您访问的页面位置。
            </p>
            <h4>三、我们如何存储您的个人信息</h4>
            <p class="notbold mag">
              3.1我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格遵照法律法规和相关监管部门的规定执行，向您说明个人信息出境的目的以及涉及的个人信息类型，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。
            </p>
            <p class="notbold mag">
              3.2我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。如您注销账号或超出必要的保存期限后，我们会根据适用法律法规的要求对所持有的您的个人信息进行删除或匿名化处理。
            </p>
            <p class="notbold mag">
              3.3如果我们发生停止产品/服务等情形，我们将会至少提前三十日向您发出通知，并在停止产品/服务后根据适用法律法规的要求对所持有的您的个人信息进行删除或匿名化处理。
            </p>
            <h4>四、我们如何保护您的个人信息</h4>
            <p class="notbold mag">
              4.1为了保障您的信息安全，我们在收集您的信息后，将采取各种合理必要的措施保护您的信息。例如，在技术开发环境当中，我们仅使用经过去标识化处理的信息进行统计分析对外提供研究报告时，我们将对报告中所包含的信息进行去标识化处理。我们会将去标识化后的信息与可用于恢复识别个人的信息分开存储，确保在针对去标识化信息的后续处理中不重新识别个人。
            </p>
            <p class="notbold mag">
              4.2我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如通过网络安全层软件（SSL）进行加密传输、信息加密在一点椅眼刺数据中心的访问。传输和存储敏感个人信息（含个人生物识别信息）时，我们将采用加密、权限控制、去标识化/匿名化等安全措施。
            </p>
            <p class="notbold mag">
              4.3我们设立了个人信息保护责任部门，针对个人信息处理开展个人信息安全影响评估。同时，我们建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权的原则对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织全
              体工作人员参加安全考试。另外，我们的相应网络/系统通过了国家网络安全等级保护的测评。我们每年还
              会聘请外部独立第三方对我们的信息安全管理体系进行评估。
            </p>
            <p class="notbold mag">
              4.4我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知∶安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况在平台上推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监
              管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。
            </p>
            <p class="notbold mag">
              4.5请您务必妥善保管好您的川渝数藏登录名及其他身份要素。您在使用我们提供的产品/服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现您的登录名及或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
            </p>
            <p class="notbold mag">
              4.6在您终止使用某项服务后，我们会停止对您个人信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形
              式通知您，并对我们所持有的您的个人信息进行删除或匿名化处理。
            </p>
            <h4>五、我们如何对外提供个人信息</h4>
            <p class="notbold mag">5.1委托处理</p>
            <p class="notbold mag">
              为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司或其他第三方代表我们来处理您的信息。我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务并采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托方不再保存个人信息。我们承诺对此负责。
            </p>
            <p class="notbold mag">5.2共享</p>
            <p class="notbold mag">5.2.1业务共享</p>
            <p class="notbold mag">
              我们承诺对您的信息进行保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括关联公司、合作机构以及其他合作伙伴。在将信息提供给第三方前，我们将尽商业上合理的努力评估该第二方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，尽力要求第三方对您的信息采取保护措施。
            </p>
            <p class="notbold mag">
              （1）向您提供服务所必需的共享。某些产品或服务可能由第三方提供或由相关服务提供方与第三方共
              同提供，因此，只有共享您的信息，才能提供您需要的产品或服务，例如∶区块链技术服务的提供方、数字藏品的发行方。
            </p>
            <p class="notbold mag">
              （2）如您选择参与我们和第三方联合开展的运营活动（如抽奖、竞赛或类似推广活动），我们可能与其共享活动过程中产生的、为完成活动所必要的信息（包括但不限于您的专属邀请码、您的数字藏品的持有数量和转赠数量等），以便第三方能及时向您发放奖品或为您提供服务，相关服务提供方会依
              据法律法规或国家标准的要求，在活动规则页面或通过其他途径向您明确告知需要向第三方提供何种个人信息。
            </p>
            <p class="notbold mag">
              （3）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
            </p>
            <p class="notbold mag">5.2.2其他用户查询</p>
            <p class="notbold mag">
              为了确保数字藏品转赠对象的准确性，避免因输错账号导致的损失，他人可通过搜索您的手机号搜索到您，并看到您账号对应的昵称、头像，以便向您转赠数字藏品。
            </p>
            <p class="notbold mag">5.2.3投诉处理</p>
            <p class="notbold mag">
              当您投诉他人或被他人投诉时
              为了保护您及他人的合法权益，我们可能会将您的姓名、有效证件号码、
              联系方式、投诉相关内容等提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。
            </p>
            <p class="notbold mag">5.3转让</p>
            <p class="notbold mag">
              我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外∶
            </p>
            <p class="notbold mag">（1）事先获得您的明确同意；</p>
            <p class="notbold mag">
              （2）根据法律法规或强制性的行政或司法要求；
            </p>
            <p class="notbold mag">
              （3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的、处理方式时，我们将要求该公司、组织重新取得您的明确同意。
            </p>
            <p class="notbold mag">5.4公开披露</p>
            <p class="notbold mag">
              5.4.1除下述情况外，原则上我们不会将您的信息进行公开披露。如确需公开披露的，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。
            </p>
            <p class="notbold mag">
              （1）您创建并发布的展馆、您的关注列表、关注数、粉丝列表、粉丝数将在您的个人主页中展示同时您发布的评论内容也会和您的账号昵称、头像一并在您发布的页面中进行展示。
            </p>
            <p class="notbold mag">
              （2）在公布中奖活动名单时会根据具体活动参与规则脱敏展示中奖者手机号或账号登录名
            </p>
            <p class="notbold mag">
              （3）对违规账号、欺诈行为进行处罚公告时，我们会公开相关账号信息
            </p>
            <p class="notbold mag">（4）法律、行政法规规定的其他情形。</p>
            <p class="notbold mag">
              5.4.2特别提示您注意，您在使用我们产品/服务时自愿发布或公开分享的信息（如分享您拥有的数字藏品），可能会涉及您或他人的个人信息甚至敏感个人信息（如您的账号昵称、头像、交易信息）。请您在使用我们的服务时谨慎地考虑，是否要发布甚至公开分享相关信息。
            </p>
            <h4>六、您如何实现您的个人信息权利</h4>
            <p class="notbold mag">
              在您使用我们的产品/服务期间，为了您可以更加便捷地访问和管理您的信息，同时保障您的个人信息权利，我们在客户端中为您提供了相应的操作设置，您可以参考下面的指引进行操作。
            </p>
            <p class="notbold mag">6.1查阅、更正和补充</p>
            <p class="notbold mag">
              您有权访问、更正、补充您的个人信息，法律法规规定的例外情况除外。您在川渝数藏中登录账号后，可以通过以下方式管理您的信息∶
            </p>
            <p class="notbold mag">
              （1）账号信息通过访问“我的-设置-账号与安全”访问、更正、补充您账号中的个人资料及账号信息、账号绑定信息及实名认证状态。
            </p>
            <p class="notbold mag">
              （2）订单信息通过访问“我的-订单”查询您账号中购买的数字藏品订单信息。
            </p>
            <p class="notbold mag">
              （3）转赠/接收记录通过访问我的-记录“查询您订单中的数字藏品转赠、接收记录。
            </p>
            <p class="notbold mag">
              如您发现相应信息有误的，您可以根据页面提示自助修改您的信息，您也可以通过官方渠道联系我们处理。
            </p>
            <p class="notbold mag">6.2删除</p>
            <p class="notbold mag">
              您可以通过6.1中列明的路径删除您的部分信息，或申请注销账号删除您的全部信息。如您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可以通过我们的官方渠道向我们提出删除个人信息的请求。
            </p>
            <p class="notbold mag">6.3管理您的授权范围</p>
            <p class="notbold mag">
              您可以通过手机设备权限设置及川渝数藏平台“我的-设置-通用-系统权限管理”来管理使用的系统权限授权。对于您无法直接通过上述方式设置的授权，您可以通过我们的官方渠道联系工作人员处理。但请注意，对于部分类型的个人信息，例如实现川渝数藏平台基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。当您撤回授权后，我们将不再处理相应
              的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。
            </p>
            <p class="notbold mag">6.4注销账号</p>
            <p class="notbold mag">
              您可通过如下路径申请注销账号在川渝数藏平台中登录账号，通过访问“我的-设置-账号与安全-账号注
              销”即可进入账号注销申请流程。当您符合约定的账号注销条件并注销某项服务相关账号后，我们将按照法律要求删除您该账号内的所有个人信息，但如有相关个人信息按照法律法规规定的保存期限未届满或者其他依法不适宜删除的情形，我们将停止除存储和采取必要的安全保护措施之外的处理。
            </p>
            <p class="notbold mag">
              6.5尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求
              ∶
            </p>
            <p class="notbold mag">（1）与国家安全、国防安全直接相关的；</p>
            <p class="notbold mag">
              （2）与公共安全、公共卫生、重大公共利益直接相关的；
            </p>
            <p class="notbold mag">
              （3）与犯罪侦查、起诉、审判和执行判决等直接相关的；
            </p>
            <p class="notbold mag">
              （4）有充分证据表明您存在主观恶意或滥用权利的；
            </p>
            <p class="notbold mag">
              （5）响应您的请求将导致其他个人、组织的合法权益受到严重损害的。
            </p>
            <p class="notbold mag">
              6.6当我们与第三方合作向您提供服务时，第三方将按照其发布的隐私权政策或与您签署的其他条款
              （如有）告知您个人信息处理者的相关信息，并按照适用的要求严格保护您的信息、告知您行使个人信息权利的方式。请您仔细阅读相关政策、条款如有疑问，可以根据您所使用的服务咨询该第三方机构。
            </p>
            <p class="notbold mag">
              6.7如您对本政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，您可以通过联系我们的官方渠道解决问题。
            </p>
            <h4>七、对第三方责任的声明</h4>
            <p class="notbold mag">
              7.1请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务（例如∶
              数字藏品的发行方）和通过我们接收您的个人信息的第三方可能有自己的隐私权保护政策当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
            </p>
            <p class="notbold mag">
              7.2我们所提供的部分服务需要由合作伙伴共同为您提供（例如∶平台的通知栏消息提醒或者地支付服务），为此，我们会在川渝数藏平台内会嵌入合作伙伴的软件工具开发包（以下简称“SDK”）或其他类似的应用程序。例如∶当您使用不同手机设备时，手机厂商提供的Push
              SDK（消息提醒服务）需要读取您的设备识别号、联网相关信息，用于推送/下发通知栏消息。我们将会对SDK或其他类似的应用程序进行严格的安全检测，并要求合作伙伴采取严格的数据保护措施，切实保障您的合法权益。具体您可以点击查阅《川渝数藏接入的第三方SDK目录》。
            </p>
            <h4>八、我们如何保护未成年人的信息</h4>
            <p class="notbold mag">
              8.1我们非常重视对未成年人个人信息的保护。我们期望父母或监护人指导未成年人使用我们的服务。我们将根据国家相关法律法规的规定保护未成年人的信息的保密性及安全性。
            </p>
            <p class="notbold mag">
              8.2如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的
              监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施
            </p>
            <p class="notbold mag">
              8.3如您为未成年人的父母或监护人，当您对您所监护的未成年人的信息处理存在疑问时，请通过上文中的联系方式联系我们。
            </p>
            <p class="notbold mag">
              8.4特别提示：14周岁以下的儿童及其监护人在为儿童注册川渝数藏账号并使用我们的服务前，敬请仔细阅读我们专门为儿童制定的《儿童个人信息保护规则》，并在征得儿童的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
            </p>
            <h4>九、本政策的适用和更新</h4>
            <p class="notbold mag">
              9.1为给您提供更好的服务，我们提供的产品/服务将不时变化，本政策也将随之更新；我们也会根据国家法律法规的变化要求，更新本政策的相关条款。更新的政策将于更新公告公布的生效之日起生效。
            </p>
            <p class="notbold mag">
              9.2发生下列重大变化情形时，我们会适时对本政策进行更新
            </p>
            <p class="notbold mag">
              （1）我们的基本情况发生变化，例如∶兼并、收购、重组引起的所有者变更；
            </p>
            <p class="notbold mag">
              （2）收集、存储、使用个人信息的范围、目的、规则发生变化；
            </p>
            <p class="notbold mag">
              （3）对外提供个人信息的对象、范围、目的发生变化；
            </p>
            <p class="notbold mag">（4）您访问和管理个人信息的方式发生变化；</p>
            <p class="notbold mag">（5）数据安全能力、信息安全风险发生变化；</p>
            <p class="notbold mag">
              （6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
            </p>
            <p class="notbold mag">
              （7）其他可能对您的个人信息权益产生重大影响的变化。
            </p>
            <p class="notbold mag">
              9.3如本政策发生更新，我们将在平台推送通知、弹窗提示、发送邮件/短消息或者在官方网站发布
              公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们提供的产品/服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
            </p>
            <h4>十、本政策中关键词说明</h4>
            <p class="notbold mag">
              个人信息：是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
            </p>
            <p class="notbold mag">
              敏感个人信息：是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
            </p>
            <p class="notbold mag">
              个人信息的处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。
            </p>
            <p class="notbold mag">
              账号信息∶指用于识别您身份的川渝数藏账号相关信息要素，包括您的川渝数藏ID（含绑定的支付宝账号
              ID）、手机号码、密码、短信校验码、头像、昵称、简介、证件号码及生物识别信息。
              数字藏品：是使用华为区块链技术进行唯一标识的经数字化的特定作品、艺术品和商品，包括但不限于数字图片、音乐、视频、3D模型等各种形式的数字藏品。数字藏品的具体发行方和权益规则等信息以相关页面的具体展示为准。
            </p>
          </div>
          <div v-else-if="footeractive == '4'">
            <h2>登录及注册认证</h2>
            <h4>一、登录</h4>
            <p class="notbold mag">
              用户在浏览器中输入域名“https://cysc.elianip.com/”，点击右侧上方的【登录】按钮，在“登录”页面上输入手机号，再点击【发送验证码】，在弹框中按提示顺序点击汉字，获取手机验证码。再输入验证码。勾选“我已阅读并同意《用户服务协议》《隐私政策》”后，即可完成登录。
            </p>
            <h4>二、注册及实名认证</h4>
            <p class="notbold mag">
              1、用户在浏览器中输入域名“https://cysc.elianip.com/”，点击右侧上方的【注册】按钮，在“注册”页面上输入手机号，再点击【发送验证码】，在弹框中按提示顺序点击汉字，获取手机验证码。再输入验证码。勾选“我已阅读并同意《用户服务协议》《隐私政策》”后，即可完成注册。（如果用户在本平台没有注册过，第一次登录时，系统自动完成该用户的基本信息注册。）。2、用户在完成基本信息“注册”后，在“个人主页”栏中，点击“实名认证”图标，进入实名认证页面，用户录入“真实姓名”和“身份证号码”后，再点击【实名认证】按钮，系统会调用腾讯云的第三方认证接口，对“姓名、身份证号码、手机号码”进行三要素认证。只有认证通过的用户，才算完成注册。才能在平台上购买物品。
            </p>
          </div>
          <div v-else-if="footeractive == '5'">
            <h2>纠纷处理协议</h2>
            <h4>一、部门职责</h4>
            <p class="notbold mag">
               1、四川易链数博科技文化有限公司指定客户服务责任人，负责客户交易纠纷的预处理，包括与客户充分沟通协商，纠纷的信息传递、材料的收集、传递和报告，并负责配合对已形成的诉讼纠纷进行处理，协助风险管理部收集相应的证据材料。
			</p>
			<p class="notbold mag">
			   2、公司相关部门建立客户投诉处理机制，明确客户投诉途径，在川渝数藏平台页面的显要位置公布投诉处理电话、电子邮箱、通讯地址，并指定专门人员负责客户投诉处理，认真、及时的处理客户的投诉和意见，及时向客户反馈处理结果，并做好记录，建立投诉处理不当的责任追究机制。
			</p>
			<p class="notbold mag">
			   3、客户服务中心负责对客户投诉的集中处理，包括设立客户服务代理接受客户电话投诉、协调处理相关部门与客户沟通无果的投诉请求，并检查相关业务部门的处理结果。
			</p>
			<p class="notbold mag">
			   4、风险管理部负责客户交易纠纷所涉法律问题的处理，包括向公司相关部门提供客户投诉处理的法律意见，处理已形成的诉讼类纠纷，以及负责对公司自行处理的诉讼纠纷进行协调、监督和检查。
			</p>
            <h4>二、客户交易纠纷的分类处理</h4>
            <p class="notbold mag">
              公司相关部门负责对客户交易纠纷信息进行分类登记，根据客户交易纠纷风险的大小，将客户交易纠纷分为以下四大类：
			</p>
			<p class="notbold mag">
			   1、因公司相关部门业务差或其他过错给客户造成损失而引发的客户交易纠纷。
			</p>
			<p class="notbold mag">
			   2、由于交易系统故障，发生应急事件等客观原因而引发的客户纠纷。
			</p>
			<p class="notbold mag">
			   3、因客户自身对系统使用、规章制度、产品购买不熟悉，或客户提出不合理要求、对员工按章办事感觉不便等原因而引发的纠纷。
			</p>
			<p class="notbold mag">
			   4、其他原因引发的客户纠纷。
			</p>
            <h4>三、非诉讼类纠纷的处理流程</h4>
            <p class="notbold mag">
              1、公司相关部门在处理客户交易纠纷过程中，应以消除矛盾、缓和纷争为原则，与客户沟通、协调。相关部门已发生的非诉讼类纠纷事项，相关部门应视情况采取跟踪核查、协商解决等措施，并及时将有关情况报公司主管部门。
			</p>
			<p class="notbold mag">
			  2、公司指定的处理客户交易纠纷的客户服务负责人，在接到客户投诉后应首先分析投诉形成原因，如为员工业务差错所导致，应由客户服务负责人为主成立协调小组，负责及时与客户协商赔偿方案：赔偿方案涉及金额不足1000元的，由部门领导审批；涉及金额超过1000元的，应经公司总经理审批后处理，并知会风险管理部。如受理的投诉内容涉及到公司相关规定或系统运行等情况时，客户服务负责人应在了解情况后与公司有关部门沟通解决，必要时应形成详细的文字材料报告给风险管理部。
			</p>
			<p class="notbold mag">
			  3、公司相关部门指定的处理客户交易纠纷的客户服务责任人，接到客户投诉后由该责任人先负责与客户联系协商，并将客户投诉情况、解决问题的方案报告给部门负责人，供其进行决策。如客户服务责任人与客户协商未果的，应将情况说明报送至部门领导，由部门领导在分析、判断基础上提出相应处理意见，再交相关业务部门处理。如投诉涉及相关法律问题的，由客户服务中心将相关法律问题以书面文件形式提交风险管理部，由风险管理部提供相应处理意见。
			<p>
            <h4>四、诉讼类纠纷的风险评估、论证及应对措施</h4>
            <p class="notbold mag">
              因客户交易纠纷被诉讼的，应及时报公司客户服务部和风险管理部，由风险管理部提出解决方案，并征询相关部门意见后，报公司领导批准后实施。风险管理部收到客户部传递的诉讼材料后，应当及时就诉讼的事实依据是否完备、法律依据是否充分、公司可能承受的败诉风险大小等问题组织论证，提出应对或处理意见。客户单独起诉公司作为共同被告的诉讼，应将处理结果报风险管理部备案：1、涉及金额较小的诉讼；2、对公司影响较大的诉讼。必要时，公司可以聘请具备资质的执业律师及律师机构协助处理诉讼事务和草拟、审核重要法律文件，公司相关部门自行处理客户交易纠纷过程中，提交给有关司法机关或其他主管机关的法律文件，以及需要公司签署的法律文件，都应经风险管理部审核。风险管理部可以直接向公司相关部门调取诉讼证据材料，经审查认为需要由相关部门补充证据材料的，可以要求其补充。由风险管理部负责处理或聘请执业律师代理的诉讼，风险管理部应及时形成该案件的进展报告，并报公司总经理。
            </p>
            <h4>五、其他事项</h4>
            <p class="notbold mag">
              本制度适用于公司相关部门涉及的客户交易纠纷。本制度未作规定的，适用于公司其他相关文件的规定。则由风险管理部负责解释。
            </p>
          </div>
          <div v-else-if="footeractive == '6'">
            <h2>未成年人保护协议</h2>
            <h4>一、限制注册与实名认证</h4>
            <p class="notbold mag">
              四川易链数博科技文化有限公司指定未成年人保护责任人，责任人定期对注册的用户身份证号码进行检查，防范未成年人在平台注册与实名认证。同时，平台从技术手段上对注册用户的身份证号码进行验证，不允许未成年人在平台注册用户。在实名认证时，从技术手段上不允许未成年人在平台上认证成功，同时，只有实名认证通过的用户才能在平台进行交易。从而从技术上限制了未成年人在平台的注册与交易。
            </p>
            <h4>二、未成年人无条件退款</h4>
            <p class="notbold mag">
              从技术手段上防范和未成年人保护负责人定期检查注册用户中，若发现未成年人用户，则做封号处理，并将未成年人用户购买款项原路退回。
            </p>
          </div>
		  
          <div v-else-if="footeractive == '7'">
            <h2>充值退款</h2>
			<h4>（一）纠纷处理流程</h4>
			<p class="notbold mag">
				<h5>1、纠纷分类登记</h5>
			</p>
			<p class="notbold mag">1.1、因公司相关部门业务差或其他过错给客户造成损失而引发的客户纠纷。</p>
			<p class="notbold mag">1.2、由于交易系统故障，发生应急事件等客观原因而引发的客户纠纷。</p>
			<p class="notbold mag">1.3 因客户自身对系统使用、规章制度、产品购买不熟悉，或客户提出不合理要求、对员工按章办事感觉不便等原因而引发的纠纷。</p>
			<p class="notbold mag">1.4 其他原因引发的客户纠纷。</p>
			<p class="notbold mag">
			  <h5>2、非诉讼类纠纷的处理流程</h5>
			</p>
			<p class="notbold mag">在处理非诉讼类纠纷过程中，应以消除矛盾、缓和纷争为原则，与客户进行沟通和协调。沟通未果需要赔偿或退款的，应由客户服务负责人为主成立协调小组，负责及时与客户协商赔偿方案：赔偿方案涉及金额不足1000元的，由部门领导审批；涉及金额超过1000元的，应经公司总经理审批后处理，并知会风险管理部。如受理的投诉内容涉及到公司相关规定或系统运行等情况时，客户服务负责人应在了解情况后与公司有关部门沟通解决，必要时应形成详细的文字材料报告给风险管理部。</p>
			<p class="notbold mag">
			  <h5>3、诉讼类纠纷的处理流程</h5>
			</p>
			<p class="notbold mag">因客户交易纠纷被诉讼的，客户服务中心负责人应及时报公司风险管理部，由风险管理部提出解决方案，并征询相关部门意见后，报公司领导批准后实施。风险管理部收到客户部传递的诉讼材料后，应当及时就诉讼的事实依据是否完备、法律依据是否充分、公司可能承受的败诉风险大小等问题组织论证，提出应对或处理意见。必要时，公司可以聘请具备资质的执业律师及律师机构协助处理诉讼事务。</p>
			<h5>4、纠纷处理流程图:</h5>
			<div class="flowsheet">
			  <img src="../assets/img/1707294214514.jpg" alt="" />
			</div>
			
            <h4>（二）系统退款</h4>
            <p class="notbold mag">
              针对用户在平台上购买物品后，发生交易纠纷需要退款时，客户服务人员通知运营人员后，运营人员登录平台后台管理系统。可根据订单号对用户发起退款，退款金额将原路返回给用户购买物品时的支付银行账户。
            </p>
			<h5>退款界面:</h5>
			<div class="flowsheet">
			  <img src="../assets/img/1707294525433.jpg" alt="" />
			</div>
          </div>
		  
          <div v-else-if="footeractive == '8'">
            <h2>企业内容自审制度</h2>
            <p class="notbold">
              为规范我公司网文文化产品及服务内容的企业自审工作，明确我公司开展相关工作的职责、流程、标准以及责任追究办法。根据《网络文化经营单位内容自审管理办法》的相关要求特制订本制度。
            </p>
            <h4>一、机构设置</h4>
            <p class="notbold mag">
              自即日起成立独立于各个业务部门的内容审查委员会，委员包括开发小组的总经理或主策划、客服部经理以及市场运营部经理。由公司总经理负责委员会的召集和日常运作事宜。委员会的主要任务是在产品开发过程中规范相关平台内容；在版本验收环节对可能涉及违反法律法规的内容进行自查自纠；在平台运营过程中随时检查各种营销素材、平台活动及其他内容是否有不适当的内容出现。并且一旦发现有违规情况的发生，委员会负责对相关责任人和部门进行查处惩戒。
            </p>
            <h4>二、日常运作</h4>
            <p class="notbold mag">
              委员会每周召集一次全体会议，就公司产品开发过程中的各个环节当中的内容来源、表现方式进行集中审查和讨论，每周在公司内公布违规内容自查结果，如没有违规内容则不公布。委员会委员有义务将最近工作过程中所开发的内容对其他委员进行公开展示，并接受质询。客服部经理将针对平台测试过程中出现的所有可能涉及违规的内容进行通告，并要求各部门限期整改。
            </p>
            <h4>三、内部培训</h4>
            <p class="notbold mag">
              为确保及时准确的了解国家最新有关政策法规，由委员会召集定期对内部人员进行有关法规的讲解和培训，并进行考试。覆盖对象主要是产品策划、美术及各部门负责人。培训不定期举行，主要是针对最新出台的法规政策，以及新近升职上任的管理人员所进行。培训基本内容包括《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》、《中华人民共和国计算机信息网络国际联网安全保护管理办法》、《四川省级政府网站集约化平台管理办法（试行）》、《网络交易平台经营者合规治理规范指引（试行）》及有关规定等学习材料。
            </p>
            <h4>四、自审内容准则</h4>
            <p class="notbold mag">网络平台不得含有以下内容：</p>
            <p class="notbold mag">（一）违反宪法确定的基本原则的；</p>
            <p class="notbold mag">（二）危害国家统一、主权和领土完整的；</p>
            <p class="notbold mag">
              （三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
            </p>
            <p class="notbold mag">
              （四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
            </p>
            <p class="notbold mag">（五）宣扬邪教、迷信的；</p>
            <p class="notbold mag">
              （六）散布谣言，扰乱社会秩序，破坏社会稳定的；
            </p>
            <p class="notbold mag">
              （七）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；
            </p>
            <p class="notbold mag">
              （八）侮辱、诽谤他人，侵害他人合法权益的；
            </p>
            <p class="notbold mag">（九）违背社会公德的；</p>
            <p class="notbold mag">
              （十）有法律、行政法规和国家规定禁止的其他内容的。
            </p>
            <h4>五、责任追究</h4>
            <p class="notbold mag">
              明确内部对审核人员、开发人员及运营人员的内容审核责任追究规定。
            </p>
            <p class="notbold mag">
              对审核人员未能在审核过程中发现问题的，予以口头警告；
            </p>
            <p class="notbold mag">
              对审核人员发现问题，但开发人员拒不修改的，予以书面警告；
            </p>
            <p class="notbold mag">
              对审核人员发现问题，但开发人员修改，审核通过后自行予以回复的，予以扣除当月奖金；
            </p>
            <p class="notbold mag">
              对运营人员在运营过程中未通知审核人员，造成不良后果，而审核人员也未及时发现的，予以扣除当月奖金，累积满三次运营人员予以调离岗位，审核人员予以取消审核资格并调离岗位；
            </p>
            <p class="notbold mag">其他造成不良后果情形的，予以书面警告。</p>
            <h4>六、自审机构人员名单</h4>
            <div class="worker">
              <p class="notbold mag">自审人员</p>
              <p class="notbold mag">工作职责</p>
            </div>
            <div class="worker">
              <p class="notbold mag">李树</p>
              <p class="notbold mag">初审人员</p>
            </div>
            <div class="worker">
              <p class="notbold mag">袁野</p>
              <p class="notbold mag">初审人员</p>
            </div>
            <div class="worker">
              <p class="notbold mag">曹童瑶</p>
              <p class="notbold mag">初审人员</p>
            </div>
            <h4>七、审核流程</h4>
            <p class="notbold mag">
              我公司按照《网络文化经营单位内容自审管理办法》规定，建立自审管理制度，对网络文化产品及服务内容进行事前审核，网络文化产品及服务的内容审核由2名以上审核人员实施，审核人员填写审核意见并签字后，报本单位内容管理负责人复核签字。我公司通过技术手段对网站（平台）运行的产品及服务的内容进行实时监管，发现违规内容的要立即停止提供，保存有关记录，内容审核记录的保存期不少于2年，重大问题向所在地省级文化行政部门报告。
            </p>
            <p class="notbold mag">具体操作：</p>
            <p class="notbold mag">1、设立自审信息安全小组</p>
            <p class="notbold mag">
              2、信息安全小组负责对公司所有网络资源进行实时监控，做到及时发现、即时处理的原则办理，并对处理结果备案，对重大有害信息事件，应在第一时间上报主管领导及相关部门。
            </p>
            <p class="notbold mag">
              3、信息安全小组负责界定、监控、受理有害信息事件，要做到即接快办；夜间、节假日值班期间接到、发现的，应于次日或节假日后的第一个工作日办理，对需紧急办理的重大信息安全事件可先处理后登记（如遇紧急情况，可直接关闭服务器等设备，暂停网络运行）。
            </p>
            <p class="notbold mag">
              4、负责查办的相关人员接到交办的事件后，应及时安排办理，要求在最短时限内处理完毕，如遇特殊情况不能按时处理完毕的，应报主管领导说明情况，可适当延长处理时间，处理结果应及时反馈给信息安全小组组长。在处理有害信息事件时，应按照处理流程，及时填写相应表单，并随处理结果报告一并存档。
            </p>
            <p class="notbold mag">
              5、处理人员应对重大有害信息时间的举报人、发现人要求保密者做到保密，有关重大的有害信息事件及处理过程不得泄密。
            </p>
            <h4>自审流程图：</h4>
            <div class="flowsheet">
              <img src="../assets/img/lct.jpeg" alt="" />
            </div>
            <p class="notbold mag right">四川易链数博科技文化有限公司</p>
            <p class="notbold mag right">2023年7月</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="upper">
        <div
          v-for="(item, i) in footerList"
          :key="i"
          @click="clickUpper(item, i)"
        >
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="below">
        <div class="footer-contact">
          <!-- <span>电话：028-855 07964</span>-->

          <!-- <span>时间：周一至周日 9:00 - 18:00</span>-->
          <!--<span>邮箱：********************</span>-->
          <!--<span>地址：中国（四川）成都市金牛区天龙大道1333号6幢19层1-2号</span>-->
          <span>四川易链数博科技文化有限公司</span>
          <span>地址：中国（四川）成都市金牛区天龙大道1333号6幢19层1-2号</span>
        </div>
        <div class="footer-record">
          <span
            >Copyright © 2023 四川易链数博科技文化有限公司. All Rights Reserved.
            版权所有</span
          >
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602001881"
            ><span class="recordICP"
              ><img src="../assets/img/ICP.png" />川公网安备
              51010602001881号</span
            ></a
          >
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >蜀ICP备2023012694号-1</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "../assets/img/神鸟.gif";
import img2 from "../assets/img/虎纽印章.gif";
import img3 from "../assets/img/frh.gif";
import img4 from "../assets/img/锦江宾馆.gif";
import img5 from "../assets/img/cdm.gif";
import img6 from "../assets/img/康熙题字.gif";
import img7 from "../assets/img/黄金邮轮.gif";
import img8 from "../assets/img/红兔.jpg";
import img9 from "../assets/img/小暑.jpg";
import img10 from "../assets/img/熊猫匠心.gif";
import img11 from "../assets/img/神鸟详情.jpg";
import img12 from "../assets/img/虎纽印章详情.jpg";
import img13 from "../assets/img/frhxq.jpg";
import img14 from "../assets/img/锦江宾馆详情.jpg";
import img15 from "../assets/img/cdmxq.jpg";
import img16 from "../assets/img/康熙题字详情.jpg";
import img17 from "../assets/img/黄金邮轮详情.jpg";
import img18 from "../assets/img/红兔详情.jpg";
import img19 from "../assets/img/小暑详情.jpg";
import img20 from "../assets/img/熊猫匠心二期详情.jpg";

export default {
  data() {
    return {
		 
		 tishi: '',
		 username: '',
		 password: '',
		 newUsername: '',
		 newPassword: '',
						
      navList: [
        "首页",
        "公司介绍",
        "新闻中心",
        "产品介绍",
        "联系我们",
        "登录",
        "注册",
      ],
      footerList: [
        "平台用户服务协议",
        "平台用户使用规则",
        "平台免责声明",
        "平台隐私政策",
        "登录及注册实名认证",
        "纠纷处理协议",
        "未成年人保护协议",
        "充值退款说明",
        "企业内容自审制度",
      ],
      productList: [
        {
          title: "纪念币“神鸟”",
          price: 29,
          bank: 0,
          url: img1,
          id: "10",
          num: 350,
        },
        {
          title: "创世·虎纽印章",
          price: 99,
          bank: 0,
          url: img2,
          id: "11",
          num: 50,
        },
        {
          title: "纪念币“芙蓉花”",
          price: 29,
          bank: 0,
          url: img3,
          id: "12",
          num: 400,
        },
        {
          title: "纪念币“锦江宾馆”",
          price: 29,
          bank: 0,
          url: img4,
          id: "13",
          num: 600,
        },
        {
          title: "成都矛",
          price: 88,
          bank: 0,
          url: img5,
          id: "14",
          num: 500,
        },
        {
          title: "康熙题字",
          price: 49,
          bank: 0,
          url: img6,
          id: "15",
          num: 500,
        },
        {
          title: "纪念币”黄金邮轮”",
          price: 29,
          bank: 0,
          url: img7,
          id: "16",
          num: 500,
        },
        {
          title: "红兔",
          price: 9.9,
          bank: 0,
          url: img8,
          id: "17",
          num: 1000,
        },
        {
          title: "小暑",
          price: 0,
          bank: 0,
          url: img9,
          id: "18",
          num: 2023,
        },
        // {
        //   title: "卧游",
        //   price: 9.9,
        //   bank: 0,
        //   url: img1,
        // },
        {
          title: "熊猫匠心",
          price: 99,
          bank: 0,
          url: img10,
          id: "19",
          num: 1000,
        },
      ],
      active: "1",
      newsActive: "",
      detailsData: {},
      footeractive: "0",
    };
  },
  methods: {
    clicknavList(index) {
		// alert(index);
      if (index == "0") {
        this.active = "1";
      } else if (index == "1") {
        this.active = "2";
      } else if (index == "2") {
        this.active = "3";
      } else if (index == "3") {
        this.active = "4";
      } else if (index == "4") {
        this.active = "5";
      } else if (index == "7") {
        this.active = "6";
      } else if (index == "8") {
        this.active = "7";
      } else if (index == "9") {
        this.active = "8";
      } else if (index == "5") { // 登录
        this.active = "11";
      } else if (index == "6") { // 注册
        this.active = "12";
      }
    },
    clickDetails(item) {
      this.active = "9";
      if (item.id == "10") {
        item.details = img11;
      } else if (item.id == "11") {
        item.details = img12;
      } else if (item.id == "12") {
        item.details = img13;
      } else if (item.id == "13") {
        item.details = img14;
      } else if (item.id == "14") {
        item.details = img15;
      } else if (item.id == "15") {
        item.details = img16;
      } else if (item.id == "16") {
        item.details = img17;
      } else if (item.id == "17") {
        item.details = img18;
      } else if (item.id == "18") {
        item.details = img19;
      } else if (item.id == "19") {
        item.details = img20;
      }
      this.detailsData = item;
    },
    clickUpper(item, index) {
      this.active = "10";
      this.footeractive = index;
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@font-face {
  font-family: "思源-medium";
  src: url("../assets/font/SourceHanSansCN-Medium.otf");
}
#content {
  height: 100%;
  width: 100%;
  // border:1px solid red;
  .header {
    // height: 10%;
    height: 88px;
    background: #000;
    display: flex;
    padding: 0px 80px;
    justify-content: space-between;
    color: #ccc;
    align-items: center;
    .logo {
      // margin-right:80px;
      // width: 120px;
      img {
        width: 250px;
        height: 70px;
      }
    }
    .nav-item {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex: 1;
      padding: 0 200px;
      .navitems {
        cursor: pointer;
      }
    }
  }
  .center {
    // padding: 40px 80px;
    height: calc(100% - 267px);
    width: 100%;
    .home {
      height: 100%;
      width: 100%;
      div {
        height: 100%;
        width: 100%;
        background-image: url("../assets/img/首页.png");
        background-size: 100% 100%;
      }
    }
    .product {
      // padding: 0px 80px;
      // height: 737px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      align-items: center;

      img {
        width: 100%;
        height: 360px;
      }

      .center-content {
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .title-box {
          width: 320px;
          height: 411px;
          background-color: #ffffff;
          box-shadow: 0px 2px 5px 0px rgba(6, 0, 1, 0.2);
          border-radius: 8px;
          overflow: hidden;
          margin-top: 20px;
          cursor: pointer;
          img {
            height: 320px;
            display: block;
            margin: 0;
            padding: 0;
            border: none;
          }
          .title {
            h6 {
              font-size: 16px;
              letter-spacing: 1px;
              color: #333333;
              margin: 20px 0px 0px 20px;
            }
            .price {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 10px 20px 0px 20px;
              p {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 1px;
                color: #ff4343;
                font-weight: bold;
              }
              span {
                font-size: 14px;
                color: #999999;
              }
            }
          }
        }
        .title-box:nth-child(3n + 1) {
          margin-right: 20px;
        }
        .title-box:nth-child(3n + 2) {
          margin-right: 20px;
        }
      }
    }
    .news {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 360px;
      }
      .contentbox {
        display: flex;
        // width: 1200px;
        align-items: center;
        justify-content: space-between;
        margin-top: 45px;
        .newscontent {
          display: flex;
          margin-right: 20px;
          img {
            width: 150px;
            height: 200px;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            cursor: pointer;
            padding: 0px 10px;
          }
          h4 {
            line-height: 40px;
            width: 300px;
          }
          p {
            text-align: right;
            line-height: 100px;
            color: #b2b2b2;
            // margin-top: 45px;
          }
        }
      }
    }
    .aboutbox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 100%;
        height: 360px;
      }
      .about {
        width: 800px;
        height: 400px;
        font-size: 26px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .phone {
          line-height: 50px;
        }
      }
    }
    .company {
      img {
        width: 100%;
        height: 360px;
      }
      .introduce {
        padding: 20px 20px 0px 20px;
        p {
          line-height: 40px;
          font-size: 18px;
          text-indent: 2em;
        }
      }
    }
    .briefing {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      > div {
        // padding-top: 15%;
        // padding-bottom: 20px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          margin-bottom: 20px;
        }
        img {
          width: 500px;
          height: 300px;
        }
        p {
          text-indent: 2em;
          line-height: 50px;
          font-size: 22px;
        }
      }
    }
    .briefings {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      overflow-y: scroll;
      > div {
        margin-top: 2%;
        // padding-bottom: 20px;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        h1 {
          margin-bottom: 20px;
        }
        img {
          width: 500px;
          height: 300px;
        }
        p {
          text-indent: 2em;
          line-height: 50px;
          font-size: 22px;
        }
      }
    }
    .detailsbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      .breadcrumb {
        width: 100%;
        height: 60px;
        background-color: #f2f2f2;
        div {
          font-size: 16px;
          line-height: 60px;
          margin-left: 460px;
          color: #999999;
          span {
            color: #333333;
          }
        }
      }
      .details {
        width: 1000px;
        .top {
          margin-top: 20px;
          display: flex;
          img {
            width: 400px;
            height: 400px;
            margin-right: 39px;
          }
          div {
            line-height: 40px;
            h5 {
              font-size: 16px;
              color: #333333;
            }
            .price {
              font-size: 18px;
              color: #ff4c4c;
              font-weight: bold;
            }
            p {
              font-size: 14px;
              color: #666666;
            }
            .bank {
              color: #365ec5;
            }
            span {
              font-size: 14px;
              color: #333333;
            }
          }
        }
        .imgs {
          width: 1000px;
          margin: 20px 0px;
        }
      }
    }
    .footerDatabox {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      display: flex;
      justify-content: center;
      .content {
        width: 1000px;
        margin: 20px 0px;
        height: 100%;
        display: flex;
        .menu {
          width: 200px;
          height: 972px;
          background-color: #f2f2f2;
          display: flex;
          flex-direction: column;
          align-items: center;
          div {
            line-height: 55px;
            color: #666666;
            font-size: 16px;
            font-weight: bold;
          }
          .act {
            color: #05619c;
          }
        }
        > div {
          margin-left: 40px;
          width: 760px;
          h2 {
            font-size: 24px;
          }
          p {
            margin-top: 18px;
            color: #333333;
            font-size: 16px;
            font-weight: bold;
          }
          h4 {
            margin-top: 13px;
            font-size: 18px;
          }
          h5 {
            margin-top: 13px;
            font-size: 16px;
          }
          .date {
            color: #666666;
            font-size: 14px;
          }
          .user {
            font-size: 14px;
          }
          .relief {
            margin-top: 5px;
            font-size: 14px;
          }
          .notbold {
            font-weight: normal;
            font-size: 14px;
          }
          .mag {
            margin-top: 5px;
          }
          .worker {
            display: flex;
            width: 150px;
            justify-content: space-between;
          }
          .flowsheet {
            width: 720px;
            display: flex;
            justify-content: center;
          }
          .right {
            text-align: right;
          }
        }
      }
    }
  }
  .footer {
    padding: 0px 80px;
    background: #000;
    height: 179px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .upper {
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 173px;
        font-size: 14px;
        line-height: 14px;
        height: 14px;
        text-align: center;
        color: #d2d2d2;
        cursor: pointer;
      }
      div:not(:last-child) {
        border-right: #b2b2b2 1px solid;
      }
    }
    .below {
      height: 83px;
      .footer-contact {
        width: 100%;
        font-family: "SourceHanSansCN-Regular";
        font-size: 16px;
        color: #ffffff;
        // opacity: 0.5;
        // margin-top: 40px;
        display: flex;
        justify-content: center;
        // height:0;
        span {
          // display: inline-block;
          margin: 0 16px;
        }
      }

      .footer-record {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // text-align: center;
        font-family: "SourceHanSansCN-Regular";
        font-size: 14px;
        color: #ffffff;
        opacity: 1;
        margin-top: 10px;

        span {
          display: inline-block;
          margin: 0 20px;
        }
        a {
          color: #ffffff;
          text-decoration: none;
        }
        .recordICP {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .login {
  .login-wrap{text-align:center;}
      input{display:block; width:250px; height:40px; line-height:40px; margin:0 auto; margin-bottom: 10px; outline:none; border:1px solid #888; padding:10px; box-sizing:border-box;}
      p{color:red;}
      button{display:block; width:250px; height:40px; line-height: 40px; margin:0 auto; border:none; background-color:#41b883; color:#fff; font-size:16px; margin-bottom:5px;}
      span{cursor:pointer;}
      span:hover{color:#41b883;}
  }  
}
</style>
