import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import * as getters from './getters'
import mutations from './mutations'
import state from './state'

import VuexPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  strict: true, // 严格模式
  plugins: [
    VuexPersistedState({
      paths: []
    })
  ]
})

export default store
