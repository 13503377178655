<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {},
};
</script>

<style lang="scss">
@font-face {
  font-family: "思源-heavy";
  src: url("./assets/font/SourceHanSansCN-Normal.otf");
}

#app {
  width: 100%;
  height: 100%;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
img,
u,
i,
em {
  font-style: normal;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: '思源-heavy';
}
html,
body {
  height: 100%;
}
img,
input {
  border: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f6f6f6;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: #747474;
}
::-webkit-scrollbar-corner {
  background: #f6f6f6;
}
</style>
